import cn from 'classnames'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import { checkIsCanBettingRate as checkIsCanBettingRateLiveSports } from 'src/libs/utils/live-sports.func'
import { checkIsCanBettingRate as checkIsCanBettingRateSports, checkIsLockAllBetting } from 'src/libs/utils/sports.func'
import { renderRating } from 'src/libs/utils/utilFuncs'
import { useSelectOptBettingLiveSport } from 'src/pages/LiveSport/hooks/useSelectOptBettingLiveSport'
import { useSelectOptBettingSport } from 'src/pages/Sports/hooks/useSelectOptBettingSport'
import { DomesticWithKeyMatch as DomesticWithKeyMatchLive } from 'src/types/live-sports.type'
import { DomesticWithKeyMatch, EUpdateDomesticType, LIST_CHECK_ONE_SIDE } from 'src/types/sports.type'

type Props = {
  wrapperClassNames?: string
  classNames?: string
  domesticData: DomesticWithKeyMatch | DomesticWithKeyMatchLive
  isLiveSports?: boolean
}

export const BoardItemUnderOver = ({
  wrapperClassNames = '',
  classNames = '',
  domesticData,
  isLiveSports = false
}: Props) => {
  const { handleSelectBettingFromBoard: handleSelectBettingFromBoardLive } = useSelectOptBettingLiveSport()
  const { handleSelectBettingFromBoard: handleSelectBettingFromBoardSport } = useSelectOptBettingSport()
  const { cart: cartPreMatch } = useCartSportStore()
  const { cart: cartInPlay } = useCartLiveSportsStore()
  const cartSport = isLiveSports ? cartInPlay : cartPreMatch
  const checkIsCanBettingRate = isLiveSports ? checkIsCanBettingRateLiveSports : checkIsCanBettingRateSports
  const chosenOptionIndex = cartSport.pick_list.findIndex((cartItem) => {
    return cartItem.domesticData.idx === domesticData.idx
  })
  const handleSelectBettingFromBoard = isLiveSports
    ? handleSelectBettingFromBoardLive
    : handleSelectBettingFromBoardSport
  let isSelectOptionBetId1 = false
  let isSelectOptionBetId3 = false

  if (chosenOptionIndex !== -1) {
    isSelectOptionBetId1 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid1
    isSelectOptionBetId3 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid3
  }
  const isLockAll = checkIsLockAllBetting({
    rate1: domesticData.rate1,
    rate3: domesticData.rate3
  })

  const mainLineArr = domesticData.main_line.split(' ')

  const isCanNotBetRate = LIST_CHECK_ONE_SIDE.includes(domesticData.sports_idx)
    ? !checkIsCanBettingRate({
        rate: domesticData.rate3,
        game_id: domesticData.game_id,
        sports_idx: domesticData.sports_idx
      })
    : !checkIsCanBettingRate({
        rate: domesticData.rate3,
        game_id: domesticData.game_id,
        sports_idx: domesticData.sports_idx
      }) ||
      !checkIsCanBettingRate({
        rate: domesticData.rate1,
        game_id: domesticData.game_id,
        sports_idx: domesticData.sports_idx
      })
  return (
    <div className={cn(wrapperClassNames)}>
      <div className='grid grid-cols-2 gap-1'>
        <button
          onClick={() => {
            if (isLockAll) {
              return
            }

            if (domesticData.status === 2 || domesticData.status3 === 2) {
              return
            }

            if (isCanNotBetRate) {
              return
            }

            handleSelectBettingFromBoard({
              betId: domesticData.betid3,
              domesticData,
              keyMatch: domesticData.keyMatch,
              rate: domesticData.rate3,
              betTeam: domesticData.away_team
            })
          }}
          className={cn(
            'lg:hover:bg-sports-primary-2 relative cursor-pointer flex justify-between items-center min-w-[0px]  rounded',
            {
              'bg-sports-secondary': !isSelectOptionBetId3,
              'bg-sports-primary-2': isSelectOptionBetId3,
              'pointer-events-none relative':
                domesticData.status === 2 || domesticData.status3 === 2 || isLockAll || isCanNotBetRate
            }
          )}
        >
          <div className='flex items-center justify-between w-full px-2 md:px-4 py-2'>
            <div className='flex items-center gap-0.5 min-w-0'>
              <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>오버</span>
              <span className='text-10 md:text-12'>
                [{mainLineArr[0]}
                {mainLineArr?.[1] ? ' ' + mainLineArr?.[1] : ''}]
              </span>
            </div>
            <span className='text-10 md:text-12'>{renderRating(domesticData.rate3)}</span>
          </div>
          {(domesticData.status === 2 || domesticData.status3 === 2 || isLockAll || isCanNotBetRate) && (
            <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-sports-black/80 pointer-events-none'>
              <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
            </div>
          )}
          {domesticData.updateType?.rate3 === EUpdateDomesticType.UP ? (
            <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
          ) : domesticData.updateType?.rate3 === EUpdateDomesticType.DOWN ? (
            <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
          ) : null}
        </button>

        <button
          onClick={() => {
            if (isLockAll) {
              return
            }

            if (domesticData.status === 2 || domesticData.status1 === 2) {
              return
            }

            if (isCanNotBetRate) {
              return
            }

            handleSelectBettingFromBoard({
              betId: domesticData.betid1,
              domesticData,
              keyMatch: domesticData.keyMatch,
              rate: domesticData.rate1,
              betTeam: domesticData.home_team
            })
          }}
          className={cn(
            'lg:hover:bg-sports-primary-2 relative cursor-pointer flex justify-between items-center min-w-[0px]  rounded',
            {
              'bg-sports-secondary': !isSelectOptionBetId1,
              'bg-sports-primary-2': isSelectOptionBetId1,
              'pointer-events-none relative':
                domesticData.status === 2 || domesticData.status1 === 2 || isLockAll || isCanNotBetRate
            }
          )}
        >
          <div className='flex items-center justify-between w-full px-2 md:px-4 py-2'>
            <div className='flex items-center gap-0.5 min-w-0'>
              <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>언더</span>
              <span className='text-10 md:text-12'>
                [{mainLineArr[0]}
                {mainLineArr?.[1] ? ' ' + mainLineArr?.[1] : ''}]
              </span>
            </div>
            <span className='text-10 md:text-12'>{renderRating(domesticData.rate1)}</span>
          </div>
          {(domesticData.status === 2 || domesticData.status1 === 2 || isLockAll || isCanNotBetRate) && (
            <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-sports-black/80 pointer-events-none'>
              <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
            </div>
          )}
          {domesticData.updateType?.rate1 === EUpdateDomesticType.UP ? (
            <div className='absolute top-0 left-0 border-l-[14px] border-b-[14px] border-transparent border-l-blue-500 animate-pulse'></div>
          ) : domesticData.updateType?.rate1 === EUpdateDomesticType.DOWN ? (
            <div className='absolute bottom-0 left-0 border-r-[14px] border-b-[14px] border-transparent border-b-red-1 animate-pulse'></div>
          ) : null}
        </button>
      </div>
    </div>
  )
}

import { useLazyQuery } from '@apollo/client'
import { Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import path from 'src/constants/path'
import { GET_DATA_LOOBY } from 'src/libs/apis/graphql/queries/game.query'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig'
import { setIsPlayingGame } from 'src/libs/stores/common.reducer'
import SpecialButton from '../ui/SpecialButton'

export default function NavBar() {
  const dispatch = useDispatch()
  const { navbarSettings } = useAdminConfig()
  const [currentLink, setCurrentLink] = useState(location.pathname)
  const { navigateLinks } = navbarSettings || {}
  const [urlGame, setUrlGame] = useState('')
  const [showGame, setShowGame] = useState(false)
  const [getSlotGame, { loading: loadingGameDetailSlot }] = useLazyQuery(GET_DATA_LOOBY, {
    context: { apiName: 'game' },
    fetchPolicy: 'no-cache'
  })

  const chooseGame = async (providerCode: string) => {
    const { data, error } = await getSlotGame({
      variables: {
        provider_code: providerCode
      },
      onCompleted(data) {
        if (window.innerWidth < 768) {
          window.open(data?.GameQuery?.url, '_blank')
        }
      }
    })

    if (error) {
      toast.error(error.message)
      setUrlGame('')
    } else {
      if (window.innerWidth < 768) return
      setShowGame(true)
      dispatch(setIsPlayingGame(true))
      setUrlGame(data?.GameQuery?.url || '')
    }
  }

  useEffect(() => {
    if (location.pathname === path.casino) {
      if (!location.search) {
        setCurrentLink(path.casino)
      } else {
        setCurrentLink(path.slot)
      }
    } else setCurrentLink(location.pathname)
  }, [location.pathname, location.search])

  return (
    <>
      <Modal
        open={showGame}
        destroyOnClose
        centered
        className='!w-screen !h-screen max-w-none m-0 p-0 top-0 left-0'
        closeIcon={null}
        footer={null}
        classNames={{
          body: 'bg-black h-full w-full',
          content: '!bg-transparent w-full h-screen !p-2 md:!p-10',
          wrapper: 'w-screen h-screen'
        }}
      >
        <div className='w-full h-full relative'>
          {urlGame ? (
            <iframe
              src={urlGame}
              frameBorder='0'
              allowFullScreen
              title='game'
              style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            loadingGameDetailSlot && (
              <Spin size='large' className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
            )
          )}
          <button
            className='absolute top-0 right-4 mt-[21px] z-10 cursor-pointer bg-transparent border-0 p-0'
            onClick={() => {
              setShowGame(false)
              dispatch(setIsPlayingGame(false))
            }}
          >
            <img
              src='/assets/images/icons/close-auth-modal.png'
              alt='close-modal'
              title='close'
              width='20'
              height='20'
            />
          </button>
        </div>
      </Modal>
      <nav className='w-full hidden lg:block h-[5dvh]'>
        <div className='mx-auto h-full flex justify-center items-center gap-[40px] w-70'>
          {navigateLinks?.map((item, index) => {
            if (item?.isSpecial) {
              return (
                <SpecialButton
                  key={index}
                  type='navbar'
                  item={item}
                  index={index}
                  chooseGame={() => chooseGame(item.providerCode || '')}
                />
              )
            }
            return (
              <NavLink
                to={item.link}
                onClick={() => {
                  setCurrentLink(item.link)
                }}
                className={() =>
                  `font-bold text-12 2xl:text-[24px] hover:text-primary ${currentLink === item.link ? 'text-primary' : 'text-white'}`
                }
                key={index}
              >
                {item.value}
              </NavLink>
            )
          })}
        </div>
      </nav>
    </>
  )
}

import { DomesticWithKeyMatch, EUpdateDomesticType, LIST_CHECK_ONE_SIDE } from 'src/types/sports.type'
import { DomesticWithKeyMatch as DomesticWithKeyMatchLive } from 'src/types/live-sports.type'
import { useSelectOptBettingLiveSport } from 'src/pages/LiveSport/hooks/useSelectOptBettingLiveSport'
import { useSelectOptBettingSport } from 'src/pages/Sports/hooks/useSelectOptBettingSport'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import cn from 'classnames'
import { renderRating } from 'src/libs/utils/utilFuncs'
import { checkIsCanBettingRate, checkIsLockAllBetting } from 'src/libs/utils/sports.func'

type Props = {
  wrapperClassNames?: string
  classNames?: string
  domesticData: DomesticWithKeyMatch | DomesticWithKeyMatchLive
  isLiveSports?: boolean
}

const MAPPING_NAME_12AndUO: { [key: string]: string } = {
  '1 And Over': '홈팀승+오버',
  '1 And Under': '홈팀승+언더',
  '2 And Over': '원정승+오버',
  '2 And Under': '원정승+언더'
}

const renderName = ({
  domesticData,
  key
}: {
  domesticData: DomesticWithKeyMatch | DomesticWithKeyMatchLive
  key: string
}) => {
  if (key === '1') {
    return domesticData.home_team
  }
  if (key === '2') {
    return domesticData.away_team
  }
  if (key === 'Under') {
    return '언더'
  }
  if (key === 'Over') {
    return '오버'
  }
  return '+'
}

export const BoardItem12AndUO = ({
  wrapperClassNames = '',
  classNames = '',
  domesticData,
  isLiveSports = false
}: Props) => {
  const { handleSelectBettingFromBoard: handleSelectBettingFromBoardLive } = useSelectOptBettingLiveSport()
  const { handleSelectBettingFromBoard: handleSelectBettingFromBoardSport } = useSelectOptBettingSport()
  const { cart: cartPreMatch } = useCartSportStore()
  const { cart: cartInPlay } = useCartLiveSportsStore()
  const cartSport = isLiveSports ? cartInPlay : cartPreMatch
  const chosenOptionIndex = cartSport.pick_list.findIndex((cartItem) => {
    return cartItem.domesticData.idx === domesticData.idx
  })
  const handleSelectBettingFromBoard = isLiveSports
    ? handleSelectBettingFromBoardLive
    : handleSelectBettingFromBoardSport
  let isSelectOptionBetId1 = false

  if (chosenOptionIndex !== -1) {
    isSelectOptionBetId1 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid1
  }

  const mainLineArr = domesticData.main_line.split(' ')

  const isCanNotBetRate = LIST_CHECK_ONE_SIDE.includes(domesticData.sports_idx)
    ? !checkIsCanBettingRate({
        rate: domesticData.rate1,
        game_id: domesticData.game_id,
        sports_idx: domesticData.sports_idx
      })
    : !checkIsCanBettingRate({
        rate: domesticData.rate3,
        game_id: domesticData.game_id,
        sports_idx: domesticData.sports_idx
      }) ||
      !checkIsCanBettingRate({
        rate: domesticData.rate1,
        game_id: domesticData.game_id,
        sports_idx: domesticData.sports_idx
      })

  const [home, and, away] = domesticData.home_team_sub.split(' ').map((item) => {
    return renderName({ domesticData, key: item })
  })

  console.log(domesticData.home_team_sub)

  return (
    <div className={cn(wrapperClassNames)}>
      <button
        onClick={() => {
          if (domesticData.status === 2 || domesticData.status1 === 2) {
            return
          }
          if (isCanNotBetRate) {
            return
          }
          handleSelectBettingFromBoard({
            betId: domesticData.betid1,
            domesticData,
            keyMatch: domesticData.keyMatch,
            rate: domesticData.rate1,
            betTeam: MAPPING_NAME_12AndUO[domesticData.home_team_sub]
          })
        }}
        className={cn(
          'w-full lg:hover:bg-sports-primary-2 relative cursor-pointer flex justify-between items-center min-w-[0px]  rounded',
          classNames,
          {
            'bg-sports-secondary': !isSelectOptionBetId1,
            'bg-sports-primary-2': isSelectOptionBetId1,
            'pointer-events-none relative': domesticData.status === 2 || domesticData.status1 === 2
          }
        )}
      >
        <div className='flex items-center justify-between w-full px-2 md:px-4 py-2'>
          <div className='flex flex-col'>
            <span
              title={`${MAPPING_NAME_12AndUO[domesticData.home_team_sub]} [${mainLineArr[0]}]`}
              className='text-10 md:text-12 text-left md:max-w-full'
            >
              {home} {and}
            </span>
            <span
              title={`${MAPPING_NAME_12AndUO[domesticData.home_team_sub]} [${mainLineArr[0]}]`}
              className='text-10 md:text-12 text-left md:max-w-full'
            >
              {away}[{mainLineArr[0]}]
            </span>
          </div>
          <span className='text-10 md:text-12'>{renderRating(domesticData.rate1)}</span>
        </div>
        {(domesticData.status === 2 || domesticData.status1 === 2) && (
          <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-sports-black/80 pointer-events-none'>
            <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
          </div>
        )}
        {domesticData.updateType?.rate1 === EUpdateDomesticType.UP ? (
          <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
        ) : domesticData.updateType?.rate1 === EUpdateDomesticType.DOWN ? (
          <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
        ) : null}
      </button>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import FormLogin from 'src/components/Form/FormLogin'
import LiveSportsCart from 'src/components/LiveSportsCart'
import MiniGameCart from 'src/components/MiniGameCart'
import ParsingCasinoCart from 'src/components/ParsingCasinoCart'
import SportCart from 'src/components/SportCart'
import VirtualSportsCart from 'src/components/VirtualSportsCart'
import path, { pathMiniGame } from 'src/constants/path'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig'
import { isDev } from 'src/libs/utils/env'
import { RootState } from 'src/libs/utils/store'
import { setOpenAuthModal } from 'src/libs/stores/auth.reducer'

export default function SideBarRight() {
  const { bannerSettings } = useAdminConfig()
  const { banners } = bannerSettings || {}
  const { right: rightBanners } = banners || {}
  const [isParsing, setIsParsing] = useState<boolean>(false)
  const location = useLocation()
  const user = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch()

  const getDevImage = () => {
    if (isDev) {
      return [
        { image: '/assets/images/banner_homepage/gift1.gif', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/gift2.gif', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/gift3.gif', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/gift4.gif', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/gift5.gif', link: '/home', target: '_blank' },
        { image: '/assets/images/banner_homepage/gift6.gif', link: '/home', target: '_blank' }
      ]
    }

    return rightBanners
  }

  useEffect(() => {
    if (
      location.pathname === path.mgm_bacarat ||
      location.pathname === path.lotus_bacarat ||
      location.pathname === path.lotus_odd_even ||
      location.pathname === path.mgm_odd_even
    ) {
      setIsParsing(true)
    } else {
      setIsParsing(false)
    }
    return () => {}
  }, [window.location.pathname])

  return (
    <div className='p-4 bg-[17,17,17] overflow-y-auto sidebar__overflow'>
      <div className=''>
        <FormLogin
          noLabel
          classNames={{
            input:
              'py-2 px-4 mb-2 placeholder-normal text-sm !bg-black !border !border-[#323232] placeholder:text-white',
            inputWrapper: 'min-h-[44px]'
          }}
          mountErrorMessage={false}
        />
      </div>
      <div>
        {isParsing ? (
          <ParsingCasinoCart />
        ) : pathMiniGame.includes(location.pathname) ? (
          <MiniGameCart />
        ) : path.live_sport === location.pathname ? (
          <LiveSportsCart />
        ) : location.pathname.includes(path.virtual_sports) ? (
          <VirtualSportsCart />
        ) : (
          <SportCart />
        )}
      </div>

      <div className='flex flex-col gap-1 mt-2 mb-[10px]'>
        {getDevImage()?.map((item, index: number) => {
          if (!item.link) {
            return
          }
          if (item.link.includes('iframe')) {
            const srcMatch = item.link.match(/src=['"]([^'"]+)['"]/)
            if (srcMatch && srcMatch?.[1]) {
              return (
                <Link key={index} to={`${path.tv_stream}?link=${srcMatch?.[1]}`} target={item?.target || '_self'}>
                  <img src={item?.image} alt='' title='' width='294' height='100' className='w-full' />
                </Link>
              )
            }
          }
          return user ? (
            <Link key={index} to={item?.link} target={item?.target || '_self'}>
              <img src={item?.image} alt='' title='' width='294' height='100' className='w-full' />
            </Link>
          ) : (
            <button
              onClick={() => {
                dispatch(setOpenAuthModal('login'))
              }}
            >
              <img src={item?.image} alt='' title='' width='294' height='100' className='w-full' />
            </button>
          )
        })}
      </div>
    </div>
  )
}

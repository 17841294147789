import {
  CONDITION_SHOW_IN_BOARD,
  ESportsCode,
  LIST_12_AND_UO_ID,
  LIST_CHECK_ONE_SIDE,
  LIST_HANDICAP_HALFTIME_PERIOD_ID,
  LIST_HANDICAP_ID,
  LIST_HTFT_ID,
  LIST_ID_CHECK_RATE,
  LIST_ONLY_SHOW_ONE_OPTION,
  LIST_OU_HALFTIME_PERIOD_ID,
  LIST_SCORE_ID,
  LIST_UNDER_OVER_HALFTIME_PERIOD_ID,
  LIST_UNDER_OVER_HOME_AWAY_OT_ID,
  LIST_UNDER_OVER_ID,
  LIST_UNDER_OVER_ID_ALL,
  LIST_WIN_LOSE_ID,
  LIST_WIN_TIE_LOSE_ID,
  MAPPING_CATEGORY_BY_SPORTS_CODE,
  SportItemClient,
  SPORTS_IDX_BASEBALL
} from 'src/types/sports.type'
import {
  BetCartType,
  BetItemPusher,
  BoardBetSportsGroup,
  BoardBetSportsItemGroup,
  Domestic,
  DomesticWithKeyMatch,
  EUpdateDomesticType,
  GroupSportListByKey,
  LiveSportItem,
  LiveSportItemClient,
  ORDER_MARKET_BY_GAME_ID_OBJ,
  GAME_ID_GROUP_BY_SPORTS,
  LIST_ID_SHOW_BY_PERIOD,
  LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS
} from '../../types/live-sports.type'
import { BetItem } from '../stores/live-sport.reducer'
import { groupDomestic } from './sports.func'

export const generateMapKeyLiveSportList = ({ liveSportItem }: { liveSportItem: LiveSportItem }) => {
  // const random = Math.random() > 0.5 ? 2 : 1
  // key map struct: league_idx|location_idx|sports_idx|start_date|status
  return `${liveSportItem.league_idx}|${liveSportItem.location_idx}|${liveSportItem.sports_idx}|${liveSportItem.start_date}|${liveSportItem.status === 2 ? 'true' : 'false'}`
}

export const checkIsValidRate = (rate: number) => {
  return rate > 1
}

export const checkIsShowInBoardLiveSports = (item: Domestic | DomesticWithKeyMatch) => {
  const isIdValid =
    LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS[item.sports_idx as ESportsCode] !== null
      ? LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS[item.sports_idx as ESportsCode]?.includes(item.game_id)
      : true

  const isRateValid = item.rate3
    ? checkIsValidRate(item.rate1) && checkIsValidRate(item.rate3)
    : checkIsValidRate(item.rate1)
  // check 1 ben
  const isShow = LIST_ID_SHOW_BY_PERIOD[item.sports_idx as ESportsCode]
    ? LIST_ID_SHOW_BY_PERIOD[item.sports_idx as ESportsCode]?.[
        Number((item as DomesticWithKeyMatch).periodSport)!
      ].includes(item.game_id)
    : true
  if (LIST_CHECK_ONE_SIDE.includes(item.sports_idx)) {
    const isCanNotBetRate = !checkIsCanBettingRate({
      rate: item.rate1,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    })
    // if (!localStorage.getItem('eric')) {
    //   return true
    // }
    return item.status !== 1 ||
      (item.rate1 === item.rate3 && item.rate1 === 1) ||
      isCanNotBetRate ||
      !isIdValid ||
      checkIsLockAllBetting({
        rate1: item.rate1,
        rate2: item.betid2 && item.rate2 ? item.rate2 : undefined,
        rate3: item.rate3
      }) ||
      !isRateValid ||
      !isShow
      ? false
      : true
  }
  // if (!localStorage.getItem('eric')) {
  //   return true
  // }
  return item.status !== 1 ||
    (item.rate1 === item.rate3 && item.rate1 === 1) ||
    !checkIsCanBettingRate({
      rate: item.rate1,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    }) ||
    !checkIsCanBettingRate({
      rate: item.rate3,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    }) ||
    checkIsLockAllBetting({
      rate1: item.rate1,
      rate2: item.betid2 && item.rate2 ? item.rate2 : undefined,
      rate3: item.rate3
    }) ||
    !isIdValid ||
    !isRateValid ||
    !isShow
    ? false
    : true
}

export const calculatorStatus = (domestic: Domestic): number => {
  if (domestic.status2 === 0) {
    return domestic.status1 === 2 || domestic.status3 === 2 ? 2 : 1
  }

  return domestic.status1 === 2 || domestic.status2 === 2 || domestic.status3 === 2 ? 2 : 1
}

export const generateGroupByGameId = (domestic: Domestic): number => {
  const groupBySport = GAME_ID_GROUP_BY_SPORTS[domestic.sports_idx as ESportsCode]
  for (const [group, listGameId] of Object.entries(groupBySport)) {
    if (listGameId.includes(domestic.game_id)) {
      return Number(group)
    }
  }
  return 0
}

export const transformDomestic = (domesticItem: Domestic, liveSportsData: LiveSportItem): DomesticWithKeyMatch => {
  const domestic = { ...domesticItem }
  domestic.status = calculatorStatus(domestic)
  domestic.status1 = domestic.status
  if (domestic.status2 !== 0) {
    domestic.status2 = domestic.status
  }
  domestic.status3 = domestic.status
  domestic.market = {
    ...domestic.market,
    group: generateGroupByGameId(domestic)
  }
  return {
    ...domestic,
    location_name: liveSportsData.location_name_kor || liveSportsData.location_name,
    updateType: {
      rate1: EUpdateDomesticType.DEFAULT,
      rate2: EUpdateDomesticType.DEFAULT,
      rate3: EUpdateDomesticType.DEFAULT
    },
    home_team: liveSportsData.home_team_kor || liveSportsData.home_team,
    away_team: liveSportsData.away_team_kor || liveSportsData.away_team,
    keyMatch: `${domestic.league_idx}|${domestic.sports_idx}|${liveSportsData.start_date}|${domestic.fixture_idx}`,
    leagueName: liveSportsData?.league_name_kor || liveSportsData.league_name || '',
    home_team_logo: liveSportsData.home_team_logo || null,
    away_team_logo: liveSportsData.away_team_logo || null
  }
}

export const transformLiveSportDomesticData = (
  domestic: Domestic,
  liveSportsData: LiveSportItem
): DomesticWithKeyMatch => {
  // key map struct: league_idx|sports_idx|start_date|fixtures_idx
  const transformDomesticData = transformDomestic(domestic, liveSportsData)
  transformDomesticData.periodSport = liveSportsData.current_period || liveSportsData.period
  return {
    ...transformDomesticData,
    isShowInBoard: checkIsShowInBoardLiveSports(transformDomesticData)
  }
}

export const generateFakeDomestic = (liveSportItem: LiveSportItem, domestic?: DomesticWithKeyMatch): Domestic => {
  if (domestic) {
    return {
      ...domestic,
      rate1: 1,
      rate2: 0,
      rate3: 1,
      status: 2
    }
  }
  return {
    idx: liveSportItem.league_idx + liveSportItem.idx,
    game_id: '1',
    sports_name: liveSportItem.sports_name,
    fixture_idx: liveSportItem.idx,
    sports_idx: liveSportItem.sports_idx,
    league_idx: liveSportItem?.league?.idx || liveSportItem.league_idx,
    game_type: 1,
    game_kind: 1,
    game_time: '',
    play_time: '',
    home_team: liveSportItem.home_team_kor || liveSportItem.home_team,
    away_team: liveSportItem.away_team_kor || liveSportItem.away_team,
    location_name: liveSportItem.location_name_kor || liveSportItem.location_name,
    home_team_sub: '',
    vs_team_sub: '',
    away_team_sub: '',

    score1: '',
    score2: '',
    score3: '',
    score4: '',
    result: '',
    isStop: '',
    state: '',
    money1: 0,
    money2: 0,
    money3: 0,
    auto_rate: 0,
    auto_result: 0,
    status: 2,
    add_rate1: '',
    add_rate2: '',
    add_rate3: '',
    rate1: 1,
    rate2: 0,
    rate3: 1,
    status1: 2,
    status2: 2,
    status3: 2,
    betid1: '',
    betid2: '',
    betid3: '',
    isMain: 0,
    main_line: '',
    market: {
      idx: 0,
      name: 'default',
      name_en: 'default',
      group: 0
    }
  }
}

export const transformDataLiveSport = (liveSportItem: LiveSportItem): LiveSportItemClient => {
  if (!liveSportItem.main || Object.keys(liveSportItem.main).length === 0 || liveSportItem.status !== 2) {
    const fakeDomestic: Domestic = generateFakeDomestic(liveSportItem)
    return {
      ...liveSportItem,
      league_name: liveSportItem.league_name_kor || liveSportItem.league_name,
      home_team: liveSportItem.home_team_kor || liveSportItem.home_team,
      away_team: liveSportItem.away_team_kor || liveSportItem.away_team,
      location_name: liveSportItem.location_name_kor || liveSportItem.location_name,
      domestic: [fakeDomestic].map((domesticItem) => {
        return transformLiveSportDomesticData(domesticItem, liveSportItem)
      }),
      count: liveSportItem?.parents
        ? groupBoardBetSports(
            liveSportItem.parents
              .filter((item) => item.market)
              .map((item) => transformLiveSportDomesticData(item, liveSportItem))
          ).reduce(
            (count, curr) =>
              count +
              groupBoardBetItem(curr.data).reduce((countList, currList) => {
                if (currList.list.length) {
                  if (LIST_12_AND_UO_ID.includes(currList.gameId)) {
                    return countList + Math.floor(currList.list.length / 4)
                  }
                  return countList + currList.list.length
                }
                return countList
              }, 0),
            0
          )
        : liveSportItem.count
    }
  }
  return {
    ...liveSportItem,
    league_name: liveSportItem.league_name_kor || liveSportItem.league_name,
    home_team: liveSportItem.home_team_kor || liveSportItem.home_team,
    away_team: liveSportItem.away_team_kor || liveSportItem.away_team,
    location_name: liveSportItem.location_name_kor || liveSportItem.location_name,
    main: liveSportItem.main,
    domestic: groupDomestic(liveSportItem.main).map((domesticItem) => {
      return transformLiveSportDomesticData(domesticItem, liveSportItem)
    }),
    count: liveSportItem?.parents
      ? groupBoardBetSports(
          groupDomestic(liveSportItem.parents.filter((item) => item.market)).map((item) =>
            transformLiveSportDomesticData(item, liveSportItem)
          )
        ).reduce(
          (count, curr) =>
            count +
            groupBoardBetItem(curr.data).reduce((countList, currList) => {
              if (currList.list.length) {
                if (LIST_12_AND_UO_ID.includes(currList.gameId)) {
                  return countList + Math.floor(currList.list.length / 4)
                }
                return countList + currList.list.length
              }
              return countList
            }, 0),
          0
        )
      : liveSportItem.count
  }
}

export const transformCartLiveSportToPickList = (betItem: BetItem): BetCartType => {
  return {
    parent_idx: betItem.betId,
    bet_code: betItem.betId,
    fixture_id: betItem.domesticData.fixture_idx,
    key: `${[betItem.domesticData.fixture_idx, betItem.domesticData.rate1, betItem.domesticData.rate2, betItem.domesticData.rate3].join('-')}`,
    status: 0, // idk
    market_name: betItem.domesticData.market.name,
    pick_title: `${betItem.domesticData.home_team} vs ${betItem.domesticData.away_team}`,
    marketId: betItem.domesticData.market.idx,
    old_rate: '',
    pick_detail: '',
    select_idx: 0, // idk
    select_pick_desc: '',
    select_rate: Number(betItem.rate),
    sports_code: betItem.domesticData.sports_idx + '',
    sports_name: betItem.domesticData.sports_name
  }
}

export const groupDataSearchPure = (data: LiveSportItemClient[]): GroupSportListByKey[] => {
  const objGroupSportList: {
    [key: string]: LiveSportItemClient[]
  } = {}
  data.forEach((sportItem) => {
    const key = generateMapKeyLiveSportList({ liveSportItem: sportItem })
    if (key in objGroupSportList) {
      objGroupSportList[key].push(sportItem)
      return
    }

    objGroupSportList[key] = [sportItem]
  })

  return Object.entries(objGroupSportList).map(([key, listGroupSportByTime]) => {
    return {
      key: key,
      listGroupSport: listGroupSportByTime.filter((listGroupSportByKeyItem) => listGroupSportByKeyItem.domestic.length)
    }
  })
}

export const updateRate = ({
  domestic,
  rateKey,
  statusKey,
  betItemPusher
}: {
  domestic: DomesticWithKeyMatch
  rateKey: 'rate1' | 'rate2' | 'rate3'
  statusKey: 'status1' | 'status2' | 'status3'
  betItemPusher: BetItemPusher
}) => {
  if (betItemPusher.rate > domestic[rateKey] && domestic.updateType) {
    domestic.updateType[rateKey] = EUpdateDomesticType.UP
  } else if (betItemPusher.rate < domestic[rateKey] && domestic.updateType) {
    domestic.updateType[rateKey] = EUpdateDomesticType.DOWN
  }
  domestic[rateKey] = betItemPusher.rate
  domestic[statusKey] = betItemPusher.status

  return domestic
}

export const checkIsCanBettingRate = ({
  rate,
  sports_idx,
  game_id,
  isMain = false
}: {
  rate?: number
  sports_idx: number
  game_id: string
  isMain?: boolean
}) => {
  if (
    (LIST_SCORE_ID.includes(game_id) && rate && rate < 50) ||
    ![...LIST_SCORE_ID, ...LIST_ID_CHECK_RATE].includes(game_id) ||
    !rate
  ) {
    return true
  }

  if (!checkIsValidRate(rate)) {
    return false
  }

  if (LIST_SCORE_ID.includes(game_id) && rate >= 50) {
    return false
  }

  if (isMain) {
    if (sports_idx === ESportsCode.Football) {
      // for test
      return true
      return rate >= 1.4 && rate <= 2.6
    }
    return rate >= 1.4 && rate <= 2.8
  }

  if (sports_idx === ESportsCode.Football) {
    // for test
    return true
    const LIST_SPECIAL_ID = ['3', '13', '2', '835', '101', '102', '61', '64', '836', '45']
    if (LIST_SPECIAL_ID.includes(game_id)) {
      return rate >= 1.41 && rate <= 2.9
    }
    // if (
    //   LIST_HANDICAP_ID.includes(game_id) ||
    //   LIST_HANDICAP_HALFTIME_PERIOD_ID.includes(game_id) ||
    //   LIST_UNDER_OVER_ID_ALL.includes(game_id)
    // ) {
    //   return rate >= 1.41 && rate <= 2.9
    // }
    return true
  }

  if (sports_idx === ESportsCode.Basketball) {
    return true
    const LIST_SPECIAL_ID = ['21', '64', '53', '77', '354', '355']

    if (LIST_SPECIAL_ID.includes(game_id)) {
      return rate >= 1.51 && rate <= 2.6
    }

    if (LIST_HANDICAP_HALFTIME_PERIOD_ID.includes(game_id) || LIST_UNDER_OVER_HALFTIME_PERIOD_ID.includes(game_id)) {
      return rate >= 1.41 && rate <= 2.65
    }
    if (
      LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) ||
      LIST_HANDICAP_ID.includes(game_id) ||
      LIST_UNDER_OVER_ID.includes(game_id)
    ) {
      return rate >= 1.6 && rate <= 2.2
    }
  }

  if (sports_idx === ESportsCode.Baseball) {
    return true
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.6 && rate <= 2.2
    }
  }

  if (sports_idx === ESportsCode.IceHockey) {
    if ([...LIST_HANDICAP_ID, ...LIST_HANDICAP_HALFTIME_PERIOD_ID, ...LIST_UNDER_OVER_ID_ALL].includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
  }

  if (sports_idx === ESportsCode.Volleyball) {
    return true
    const LIST_SPECIAL_ID = ['866']
    if ([...LIST_HANDICAP_HALFTIME_PERIOD_ID, ...LIST_SPECIAL_ID].includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.4 && rate <= 2.6
    }
  }

  if (sports_idx === ESportsCode.Handball) {
    return true
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.75 && rate <= 2.1
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.75 && rate <= 1.95
    }
  }

  if (sports_idx === ESportsCode.American_Football) {
    return true
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.7 && rate <= 2.1
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.8 && rate <= 1.85
    }
  }

  if ([ESportsCode.Tennis].includes(sports_idx)) {
    // if (sports_idx === ESportsCode.Tennis || sports_idx === ESportsCode.E_Game) {
    if (LIST_UNDER_OVER_HOME_AWAY_OT_ID.includes(game_id) || LIST_UNDER_OVER_ID.includes(game_id)) {
      return rate >= 1.8 && rate <= 2.1
    }
    if (LIST_HANDICAP_ID.includes(game_id)) {
      return rate >= 1.75 && rate <= 1.95
    }
  }

  if (sports_idx === ESportsCode.E_Game) {
    return true
  }

  if (sports_idx === ESportsCode.Table_Tennis) {
    return true
  }

  return true
}

export const validOptionScore = ({
  sportData,
  domesticData
}: {
  sportData?: LiveSportItemClient | SportItemClient
  domesticData: DomesticWithKeyMatch
}): boolean => {
  if (!sportData) {
    return false
  }
  const { home_team_sub } = domesticData
  const { home_score: currentHomeScore, away_score: currentAwayScore } = sportData
  const [checkHomeScore, checkAwayScore] = home_team_sub.split('-').map(Number)
  if (currentHomeScore > checkHomeScore) {
    return false
  }

  if (currentAwayScore > checkAwayScore) {
    return false
  }

  if (currentHomeScore === checkHomeScore && currentAwayScore >= checkAwayScore) {
    return false
  }

  if (currentAwayScore === checkAwayScore && currentHomeScore >= checkHomeScore) {
    return false
  }

  return true
}

export const checkIsLockAllBetting = ({ rate1, rate2, rate3 }: { rate1: number; rate2?: number; rate3: number }) => {
  let minRate = 2

  if (rate2 !== undefined) {
    minRate = 3
    if (rate2 >= minRate && rate1 >= minRate && rate3 >= minRate) {
      return true
    }
  } else if (rate1 >= minRate && rate3 >= minRate) {
    return true
  }

  return false
}

export const groupBoardBetItem = (domesticList: DomesticWithKeyMatch[]): BoardBetSportsItemGroup[] => {
  const obj = new Map<string, BoardBetSportsItemGroup>()
  domesticList.forEach((item) => {
    let order = ORDER_MARKET_BY_GAME_ID_OBJ?.[item.sports_idx as ESportsCode]?.[item.market.group || 0]?.findIndex(
      (gameId) => gameId === item.game_id
    )
    order = order === -1 ? 9999 : order

    const key = item.game_id + item.market.name

    if (!obj.has(key)) {
      obj.set(key, {
        order: order,
        marketName: item.market.name,
        gameId: item.game_id,
        sportsCode: item.sports_idx,
        list: [item]
      })
    } else {
      obj.get(key)!.list.push(item)
    }
  })

  const orderList = [...Array.from(obj.values())]
    .sort((a, b) => a.order - b.order)
    .map((item) => {
      let listItem = [...item.list].sort((a, b) => {
        return Number(a?.main_line?.split(' ')[0]) - Number(b?.main_line?.split(' ')[0])
      })
      if (LIST_SCORE_ID.includes(item.gameId)) {
        listItem = [...item.list].sort((a, b) => {
          const textA = a.home_team_sub.toUpperCase()
          const textB = b.home_team_sub.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
      } else if (LIST_HTFT_ID.includes(item.gameId)) {
        const listSort = [
          '1/1',
          '1/2',
          '1/X',
          '2/1',
          '2/2',
          '2/X',
          'X/1',
          'X/2',
          'X/X',
          'Away 1',
          'Away 2',
          'Away 3',
          'Away 4+',
          'Home 1',
          'Home 2',
          'Home 3',
          'Home 4+',
          'No Goal',
          'Score Draw',
          '1st Period',
          '2nd Period',
          '3rd Period',
          '4th Period',
          'Draw'
        ]
        listItem = [...item.list]
          .map((i) => ({ ...i, orderById: listSort.findIndex((sort) => sort === i.home_team_sub) }))
          .sort((a, b) => {
            return a.orderById - b.orderById
          })
      } else if (LIST_12_AND_UO_ID.includes(item.gameId)) {
        const listSort = ['1 And Over', '1 And Under', '2 And Over', '2 And Under']
        listItem = [...listItem]
          .map((i) => ({ ...i, orderById: listSort.findIndex((sort) => sort === i.home_team_sub) }))
          .sort((a, b) => {
            return a.orderById - b.orderById
          })
      }
      return {
        ...item,
        list: listItem
      }
    })

  // tách ra tốn thêm 1 loop nhưng dễ đọc.
  if (domesticList.length) {
    if (LIST_ONLY_SHOW_ONE_OPTION.includes(domesticList[0].sports_idx)) {
      return orderList.map((item) => {
        if (
          LIST_HANDICAP_ID.includes(item.gameId) ||
          LIST_UNDER_OVER_ID.includes(item.gameId) ||
          LIST_OU_HALFTIME_PERIOD_ID.includes(item.gameId) ||
          LIST_HANDICAP_HALFTIME_PERIOD_ID.includes(item.gameId)
        ) {
          // order list đã sort mainline từ nhỏ đến lớn
          // get main_line nhỏ nhất => phần tử đầu tiên
          // get main_line lớn nhất => phần tử cuối cùng cuối cùng
          return {
            ...item,
            list: item.list.length ? [item.list[0]] : []
          }
        }
        return item
      })
    }
    if (domesticList[0].sports_idx === ESportsCode.Football) {
      return orderList.map((item) => {
        if (['836', '45'].includes(item.gameId)) {
          // order list đã sort mainline từ nhỏ đến lớn
          // get main_line nhỏ nhất => phần tử đầu tiên
          // get main_line lớn nhất => phần tử cuối cùng cuối cùng
          return {
            ...item,
            list: item.list.length ? [item.list[0]] : []
          }
        }
        return item
      })
    }
  }

  return orderList
  // const filteredConditionRateList = orderList
  //   .map((item) => {
  //     const indexTarget = getIndexTarget(item.list)
  //     return {
  //       ...item,
  //       list: item.list.filter((_, index) => {
  //         return (
  //           indexTarget === -999 ||
  //           (indexTarget !== -1 && [indexTarget - 1, indexTarget, indexTarget + 1].includes(index))
  //         )
  //       })
  //     }
  //   })
  //   .filter((item) => item.list.length)
  // return filteredConditionRateList
}

export const groupBoardBetSports = (dataRateInfoList: DomesticWithKeyMatch[]): BoardBetSportsGroup[] => {
  const obj = new Map<number, BoardBetSportsGroup>()

  dataRateInfoList
    .filter((item) => item.isShowInBoard)
    .forEach((item) => {
      if (!obj.has(item.market.group || 0)) {
        obj.set(item.market.group || 0, {
          key: item.market.group || 0,
          group: item.market.group || 0,
          name: item.market?.group
            ? MAPPING_CATEGORY_BY_SPORTS_CODE[item.sports_idx]?.[item.market.group]
            : MAPPING_CATEGORY_BY_SPORTS_CODE[item.sports_idx]?.[0],
          sportsCode: item.sports_idx,
          data: [item]
        })
      } else {
        obj.get(item.market.group || 0)!.data.push(item)
      }
    })

  const orderList = [...Array.from(obj.values())].sort((a, b) => a.group - b.group)

  if (orderList.findIndex((item) => item.group === 0) === -1) {
    return orderList
  }

  const [firstGroup, ...restGroup] = orderList

  return [...restGroup, firstGroup]
}

export const getIndexTarget = (dataRateInfoList: DomesticWithKeyMatch[]) => {
  if (dataRateInfoList.length === 0) {
    return -999
  }

  if (
    !LIST_UNDER_OVER_ID_ALL.includes(dataRateInfoList[0].game_id) &&
    !LIST_HANDICAP_ID.includes(dataRateInfoList[0].game_id)
  ) {
    return -999
  }

  const conditionSports = CONDITION_SHOW_IN_BOARD[dataRateInfoList[0].sports_idx as ESportsCode]
  if (!conditionSports || !conditionSports.isNeedCheck) {
    return -999
  }

  const { condition } = conditionSports
  const itemMatchConditionList = dataRateInfoList.filter((option) => {
    return option.rate1 <= condition.max && option.rate1 >= condition.min
  })
  if (itemMatchConditionList.length) {
    if (LIST_UNDER_OVER_ID_ALL.includes(itemMatchConditionList[0].game_id)) {
      const sortDesc = itemMatchConditionList.sort((a, b) => {
        return Number(b.main_line.split(' ')[0]) - Number(a.main_line.split(' ')[0])
      })
      return dataRateInfoList.findIndex((item) => item.idx === sortDesc[0].idx)
    }
    if (LIST_HANDICAP_ID.includes(itemMatchConditionList[0].game_id)) {
      const sortDesc = itemMatchConditionList.sort((a, b) => {
        return Number(a?.main_line?.split(' ')[0]) - Number(b?.main_line?.split(' ')[0])
      })
      return dataRateInfoList.findIndex((item) => item.idx === sortDesc[0].idx)
    }
  }

  return -1
}

import cn from 'classnames'
import { ReactNode } from 'react'
import { ArrowUpIcon } from 'src/components/Icons'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import {
  DomesticWithKeyMatch,
  EUpdateDomesticType,
  LIST_HANDICAP_ID,
  LIST_UNDER_OVER_ID_ALL,
  LIST_WIN_LOSE_ID,
  LIST_WIN_TIE_LOSE_ID,
  SportItemClient
} from 'src/types/sports.type'
import { useSelectOptBettingSport } from '../hooks/useSelectOptBettingSport'
import { renderRating } from 'src/libs/utils/utilFuncs'
import { checkIsCanBettingRate, checkIsLockAllBetting } from 'src/libs/utils/sports.func'

type Props = {
  domesticData: DomesticWithKeyMatch
  isLastItem?: boolean
  sportData: Partial<SportItemClient>
  render?: ({
    isActiveSideRight,
    isOpenBoardBetSport
  }: {
    isActiveSideRight: boolean
    isOpenBoardBetSport: boolean
  }) => ReactNode
}

export const DomesticItem = ({ domesticData, render }: Props) => {
  const { handleSelectBetting } = useSelectOptBettingSport()
  const { dataRateInfoList, isOpenBoardBetSport } = useSportSearchStore()
  const { cart: cartSport } = useCartSportStore()
  const isActiveSideRight =
    dataRateInfoList.length > 0 &&
    isOpenBoardBetSport &&
    dataRateInfoList[0].fixture_idx === domesticData.fixture_idx &&
    dataRateInfoList[0].league_idx === domesticData.league_idx &&
    dataRateInfoList[0].sports_idx === domesticData.sports_idx
      ? true
      : false
  const chosenOptionIndex = cartSport.pick_list.findIndex((cartItem) => {
    return cartItem.domesticData.idx === domesticData.idx
  })
  let isSelectOptionBetId1 = false
  let isSelectOptionBetId2 = false
  let isSelectOptionBetId3 = false
  if (chosenOptionIndex !== -1) {
    isSelectOptionBetId1 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid1
    isSelectOptionBetId2 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid2
    isSelectOptionBetId3 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid3
  }
  const isLockAll = checkIsLockAllBetting({
    rate1: domesticData.rate1,
    rate2: domesticData.rate2 && domesticData.betid2 ? domesticData.rate2 : undefined,
    rate3: domesticData.rate3
  })

  const renderRate2 = (gameId: string, gameType: number, rate2: number, main_line: string) => {
    if (LIST_WIN_TIE_LOSE_ID.includes(gameId)) {
      return rate2 !== 0 ? renderRating(rate2) : rate2
    }

    if (LIST_WIN_LOSE_ID.includes(gameId)) {
      return 'VS'
    }

    if (LIST_HANDICAP_ID.includes(gameId)) {
      return main_line.split(' ')[0]
    }

    if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return main_line
    }
  }

  const renderIconLeft = (gameId: string, gameType: number) => {
    if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return <ArrowUpIcon className='size-6 text-red-1 hidden md:block' />
    }

    if ([...LIST_WIN_LOSE_ID, ...LIST_WIN_TIE_LOSE_ID].includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/1x2.png'
          alt='sport icon'
          className='w-3 h-[5px] mr-[5px] text-red-1 hidden md:block'
        />
      )
    }

    if (LIST_HANDICAP_ID.includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/handicap-icon.png'
          alt='sport icon'
          className='w-[6px] h-[7px] text-red-1 mr-[8px] hidden md:block'
        />
      )
    }
    return null
  }
  const renderIconRight = (gameId: string, gameType: number) => {
    if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return <ArrowUpIcon className='size-6 rotate-180 text-blue-3 hidden md:block' />
    }

    if ([...LIST_WIN_LOSE_ID, ...LIST_WIN_TIE_LOSE_ID].includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/1x2.png'
          alt='sport icon'
          className='w-3 h-[5px] ml-[5px] text-red-1 hidden md:block'
        />
      )
    }

    if (LIST_HANDICAP_ID.includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/handicap-icon.png'
          alt='sport icon'
          className='w-[6px] h-[7px] text-red-1 ml-[8px] hidden md:block'
        />
      )
    }
    return null
  }

  const getClassRate2 = (gameId: string, gameType: number) => {
    if (LIST_HANDICAP_ID.includes(gameId)) {
      return 'pointer-events-none text-sports-primary'
    }

    if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return 'pointer-events-none text-sports-primary'
    }
  }

  return (
    <>
      <div key={domesticData.idx} className='grid grid-cols-[56px_1fr_40px_1fr] gap-1 items-center h-10  px-1'>
        <span className='text-10 min-w-0 md:text-12 text-sports-primary text-center'>
          {domesticData.market.name.split(' ')[0]}
        </span>
        <button
          className={cn('h-full min-w-0 cursor-pointer rounded duration-150 lg:hover:bg-primary-2', {
            'bg-sports-secondary-2': LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)
              ? !isSelectOptionBetId3
              : !isSelectOptionBetId1,
            'bg-sports-primary-2': LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)
              ? isSelectOptionBetId3
              : isSelectOptionBetId1,
            'pointer-events-none relative':
              domesticData.status === 2 ||
              isLockAll ||
              !checkIsCanBettingRate({
                rate: domesticData.rate1,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              }) ||
              !checkIsCanBettingRate({
                rate: domesticData.rate3,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              })
          })}
          onClick={() => {
            if (domesticData.status === 2) {
              return
            }
            if (
              !checkIsCanBettingRate({
                rate: domesticData.rate1,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              }) ||
              !checkIsCanBettingRate({
                rate: domesticData.rate3,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              })
            ) {
              return
            }
            if (LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)) {
              if (domesticData.status3 === 2) {
                return
              }
              handleSelectBetting({
                betId: domesticData.betid3,
                domesticData,
                keyMatch: domesticData.keyMatch,
                rate: domesticData.rate3,
                betTeam: domesticData.away_team
              })
              return
            }
            if (domesticData.status1 === 2) {
              return
            }

            handleSelectBetting({
              domesticData,
              betId: domesticData.betid1,
              keyMatch: domesticData.keyMatch,
              rate: domesticData.rate1,
              betTeam: domesticData.home_team
            })
          }}
        >
          <div className='relative h-full'>
            <div className='h-full flex items-center justify-between px-2 md:px-3'>
              <div className='flex items-center gap-1'>
                {domesticData.home_team_logo && (
                  <img src={domesticData.home_team_logo} alt='home team logo' className='w-4 hidden md:block' />
                )}
                <span className='text-12 !text-ellipsis !whitespace-nowrap !overflow-hidden !max-w-[50px] min-[375px]:!max-w-[60px] min-[430px]:!max-w-[100px] cut-text'>
                  {LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id) ? '오버' : domesticData.home_team}
                </span>
              </div>
              {
                <div className='flex items-center'>
                  {renderIconLeft(domesticData.game_id, domesticData.game_type)}
                  <span className='text-12 min-w-8'>
                    {LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)
                      ? renderRating(domesticData.rate3)
                      : renderRating(domesticData.rate1)}
                  </span>
                </div>
              }
            </div>
            {/* for case update */}
            {LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id) ? (
              domesticData.updateType?.rate3 === EUpdateDomesticType.UP ? (
                <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
              ) : domesticData.updateType?.rate3 === EUpdateDomesticType.DOWN ? (
                <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
              ) : null
            ) : domesticData.updateType?.rate1 === EUpdateDomesticType.UP ? (
              <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
            ) : domesticData.updateType?.rate1 === EUpdateDomesticType.DOWN ? (
              <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
            ) : null}
          </div>
          {(domesticData.status === 2 ||
            isLockAll ||
            !checkIsCanBettingRate({
              rate: domesticData.rate1,
              sports_idx: domesticData.sports_idx,
              game_id: domesticData.game_id,
              isMain: true
            }) ||
            !checkIsCanBettingRate({
              rate: domesticData.rate3,
              sports_idx: domesticData.sports_idx,
              game_id: domesticData.game_id,
              isMain: true
            })) && (
            <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-sports-black/80 pointer-events-none'>
              <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
            </div>
          )}
        </button>
        <button
          className={cn(
            'flex items-center min-w-0 justify-center w-full aspect-square rounded duration-150 cursor-pointer relative',
            {
              'text-sports-primary': !domesticData.betid2 && domesticData.rate2,
              'bg-sports-secondary-2': !isSelectOptionBetId2,
              'bg-sports-primary-2': isSelectOptionBetId2
            },
            getClassRate2(domesticData.game_id, domesticData.game_type)
          )}
          onClick={() => {
            // const rules = getRulesBySportsIdx(domesticData.sports_idx)
            // if (rules && !rules.is_can_bet_draw) {
            //   return
            // }
            if (domesticData.status === 2 || domesticData.status2 === 2) {
              return
            }
            domesticData.rate2 &&
              domesticData.betid2 &&
              handleSelectBetting({
                domesticData,
                betId: domesticData.betid2,
                keyMatch: domesticData.keyMatch,
                rate: domesticData.rate2,
                isDrawOption: true,
                betTeam: '무'
              })
          }}
        >
          {/* {domesticData.rate2 ? domesticData.rate2 : 'VS'} */}
          {renderRate2(domesticData.game_id, domesticData.game_type, domesticData.rate2, domesticData.main_line)}
          {/* for case update */}
          {domesticData.updateType?.rate2 === EUpdateDomesticType.UP ? (
            <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
          ) : domesticData.updateType?.rate2 === EUpdateDomesticType.DOWN ? (
            <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
          ) : null}
        </button>
        <button
          className={cn('h-full min-w-0 cursor-pointer rounded duration-150 lg:hover:bg-primary-2', {
            'bg-sports-secondary-2': LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)
              ? !isSelectOptionBetId1
              : !isSelectOptionBetId3,
            'bg-sports-primary-2': LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)
              ? isSelectOptionBetId1
              : isSelectOptionBetId3,
            'pointer-events-none relative':
              domesticData.status === 2 ||
              isLockAll ||
              !checkIsCanBettingRate({
                rate: domesticData.rate1,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              }) ||
              !checkIsCanBettingRate({
                rate: domesticData.rate3,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              })
          })}
          onClick={() => {
            if (domesticData.status === 2) {
              return
            }
            if (
              !checkIsCanBettingRate({
                rate: domesticData.rate1,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              }) ||
              !checkIsCanBettingRate({
                rate: domesticData.rate3,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id,
                isMain: true
              })
            ) {
              return
            }
            if (LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)) {
              handleSelectBetting({
                betId: domesticData.betid1,
                domesticData,
                keyMatch: domesticData.keyMatch,
                rate: domesticData.rate1,
                betTeam: domesticData.home_team
              })
              return
            }

            if (domesticData.status3 === 2) {
              return
            }

            handleSelectBetting({
              domesticData,
              betId: domesticData.betid3,
              keyMatch: domesticData.keyMatch,
              rate: domesticData.rate3,
              betTeam: domesticData.away_team
            })
          }}
        >
          <div className='relative h-full'>
            <div className='h-full flex items-center justify-between px-2 md:px-3'>
              {
                <div className='flex items-center'>
                  <span className='text-12 cut-text min-w-8'>
                    {LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id)
                      ? renderRating(domesticData.rate1)
                      : renderRating(domesticData.rate3)}
                  </span>
                  {renderIconRight(domesticData.game_id, domesticData.game_type)}
                </div>
              }
              <div className='flex items-center gap-1'>
                <span className='text-12 !text-ellipsis !whitespace-nowrap !overflow-hidden !max-w-[50px] min-[375px]:!max-w-[60px] min-[430px]:!max-w-[100px] cut-text'>
                  {LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id) ? '언더' : domesticData.away_team}
                </span>
                {domesticData.away_team_logo && (
                  <img src={domesticData.away_team_logo} alt='away team logo' className='w-4 hidden md:block' />
                )}
              </div>
            </div>
            {/* for case update */}
            {LIST_UNDER_OVER_ID_ALL.includes(domesticData.game_id) ? (
              domesticData.updateType?.rate1 === EUpdateDomesticType.UP ? (
                <div className='absolute top-0 left-0 border-l-[14px] border-b-[14px] border-transparent border-l-blue-500 animate-pulse'></div>
              ) : domesticData.updateType?.rate1 === EUpdateDomesticType.DOWN ? (
                <div className='absolute bottom-0 left-0 border-r-[14px] border-b-[14px] border-transparent border-b-red-1 animate-pulse'></div>
              ) : null
            ) : domesticData.updateType?.rate3 === EUpdateDomesticType.UP ? (
              <div className='absolute top-0 left-0 border-l-[14px] border-b-[14px] border-transparent border-l-blue-500 animate-pulse'></div>
            ) : domesticData.updateType?.rate3 === EUpdateDomesticType.DOWN ? (
              <div className='absolute bottom-0 left-0 border-r-[14px] border-b-[14px] border-transparent border-b-red-1 animate-pulse'></div>
            ) : null}
          </div>
          {(domesticData.status === 2 ||
            isLockAll ||
            !checkIsCanBettingRate({
              rate: domesticData.rate1,
              sports_idx: domesticData.sports_idx,
              game_id: domesticData.game_id,
              isMain: true
            }) ||
            !checkIsCanBettingRate({
              rate: domesticData.rate3,
              sports_idx: domesticData.sports_idx,
              game_id: domesticData.game_id,
              isMain: true
            })) && (
            <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-sports-black/80 pointer-events-none'>
              <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
            </div>
          )}
        </button>
      </div>
      {render && render({ isActiveSideRight, isOpenBoardBetSport })}
    </>
  )
}

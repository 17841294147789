import { createAction, createReducer } from '@reduxjs/toolkit'
import { DomesticWithKeyMatch, FolderBonusItem, FolderBonusQueryRes, LimitSport } from 'src/types/sports.type'

export type BetItem = {
  // domesticData: Omit<Domestic, 'betid1' | 'betid2' | 'betid3' | 'rate1' | 'rate2' | 'rate3'>
  domesticData: DomesticWithKeyMatch
  betId: string
  keyMatch: string
  rate: number
  newRate?: number
  isChanged?: boolean
  isLocked?: boolean
  betTeam: string
  status?: number
}

export type BetFolderItem = {
  rate?: number
  key?: number
  text?: string
  bet_list_length?: number
}
interface SportState {
  cart: {
    pick_list: BetItem[]
    folder_bonus?: BetFolderItem
    bettingLimit?: LimitSport
  }
  folderBonusQuery?: FolderBonusQueryRes
  isBetting?: boolean
  isRealTime: boolean
}

const initialState: SportState = {
  cart: {
    pick_list: [],
    folder_bonus: {},
    bettingLimit: {
      BettingLimitsSportQuery: {
        max_bet_amount: 0,
        max_bet_payout: 0,
        max_payout: 0,
        min_bet_amount: 0,
        percent_distribution: 0
      }
    }
  },
  folderBonusQuery: undefined,
  isBetting: false,
  isRealTime: true
}

export const removeAllCartSport = createAction('removeAllCartSport')

export const addItemToCartSport = createAction(
  'addItemToCartSport',
  function ({ domesticData, betId, keyMatch, rate, isChanged = false, newRate, betTeam }: BetItem) {
    const payload: BetItem = { domesticData, betId, keyMatch, rate, isChanged, newRate, betTeam }
    return {
      payload
    }
  }
)

export const updateItemFromCartSport = createAction(
  'updateItemFromCartSport',
  function ({
    domesticIdx,
    betId,
    rate,
    newRate,
    betTeam
  }: {
    domesticIdx: string
    betId: string
    rate: number
    newRate?: number
    betTeam: string
  }) {
    const payload = { domesticIdx, betId, rate, newRate, betTeam }
    return {
      payload
    }
  }
)
export const removeItemFromCartSport = createAction(
  'removeItemFromCartSport',
  function ({ domesticIdx, betId }: { domesticIdx: string; betId: string }) {
    const payload = { domesticIdx, betId }
    return {
      payload
    }
  }
)

export const addMiniGameToCart = createAction(
  'addMiniGameToCart',
  function (data: {
    [key: string]: {
      text: string
      rate: number
      category: string
      transId: string
      details: string
    }
  }) {
    return {
      payload: data
    }
  }
)
export const removeMiniGameToCart = createAction('removeMiniGameToCart', function ({ item }: { item: string }) {
  return {
    payload: item
  }
})

export const addFolderBonusToCart = createAction(
  'addFolderBonusToCart',
  function ({ folderBonus }: { folderBonus: FolderBonusItem }) {
    return {
      payload: folderBonus
    }
  }
)

export const removeFolderBonusFromCart = createAction('removeFolderBonusFromCart', function ({ key }: { key: number }) {
  return {
    payload: key
  }
})

export const removeAllItemInMatchAndAddNewItem = createAction(
  'removeAllItemInMatchAndAddNewItem',
  function ({ keyMatch, dateBet }: { keyMatch: string; dateBet: BetItem }) {
    return {
      payload: { keyMatch, dateBet }
    }
  }
)

export const removeItemFromCartAndAddNewItem = createAction(
  'removeItemFromCartAndAddNewItem',
  function ({ domesticIdx, betItem }: { domesticIdx: string; betItem: BetItem }) {
    return {
      payload: { domesticIdx, betItem }
    }
  }
)

export const storeLimitSportBetting = createAction(
  'storeLimitSportBetting',
  function ({ limitSport }: { limitSport: LimitSport }) {
    return {
      payload: limitSport
    }
  }
)

export const updateAndLockItem = createAction(
  'updateAndLockItem',
  function ({
    domesticIdx,
    betId,
    isChanged = false,
    newRate,
    isLocked,
    status
  }: {
    domesticIdx: string
    betId: string
    isChanged?: boolean
    newRate: number
    isLocked?: boolean
    status?: number
  }) {
    return {
      payload: { domesticIdx, betId, isChanged, newRate, isLocked, status }
    }
  }
)

export const storeFolderBonusQuery = createAction(
  'storeFolderBonusQuery',
  function ({ folderBonusQuery }: { folderBonusQuery: FolderBonusQueryRes }) {
    return {
      payload: folderBonusQuery
    }
  }
)

export const setIsBetting = createAction('setIsBetting', (isBetting: boolean) => {
  return {
    payload: isBetting
  }
})

export const setIsRealTime = createAction('setIsRealTime', (isRealTime: boolean) => {
  return {
    payload: isRealTime
  }
})

const sportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addItemToCartSport, (state, action) => {
      const status = action.payload.status || 1
      state.cart.pick_list.push({ ...action.payload, status })
      const updatedCartSport = [...state.cart.pick_list]
      const listOptionValid = updatedCartSport.filter(
        (item) => Number(item.rate) > (state.folderBonusQuery?.min_rate || 0)
      )
      if (
        updatedCartSport.length < (state.cart.folder_bonus?.bet_list_length || 0) ||
        listOptionValid.length < (state.cart.folder_bonus?.bet_list_length || 0)
      ) {
        state.cart.folder_bonus = {}
      }
    })
    .addCase(updateItemFromCartSport, (state, action) => {
      const existingCartItemIndex = state.cart.pick_list.findIndex(
        (item) => item.domesticData.idx === action.payload.domesticIdx
      )
      if (existingCartItemIndex !== -1) {
        const updatedCartSport = [...state.cart.pick_list]
        updatedCartSport[existingCartItemIndex].betId = action.payload.betId
        updatedCartSport[existingCartItemIndex].rate = action.payload.rate
        updatedCartSport[existingCartItemIndex].betTeam = action.payload.betTeam
        state.cart.pick_list = updatedCartSport
        const listOptionValid = updatedCartSport.filter(
          (item) => Number(item.rate) > (state.folderBonusQuery?.min_rate || 0)
        )

        if (
          updatedCartSport.length < (state.cart.folder_bonus?.bet_list_length || 0) ||
          listOptionValid.length < (state.cart.folder_bonus?.bet_list_length || 0)
        ) {
          state.cart.folder_bonus = {}
        }
      }
    })
    .addCase(removeItemFromCartSport, (state, action) => {
      let updatedCartSport = [...state.cart.pick_list]
      updatedCartSport = updatedCartSport.filter((cartItem) => {
        return cartItem.betId !== action.payload.betId && cartItem.domesticData.idx !== action.payload.domesticIdx
      })
      console.log('removeItemFromCartSport', updatedCartSport)
      console.log('state.cart.folder_bonus?.rate', state.cart.folder_bonus?.rate)
      state.cart.pick_list = updatedCartSport
      const listOptionValid = updatedCartSport.filter(
        (item) => Number(item.rate) > (state.folderBonusQuery?.min_rate || 0)
      )
      if (
        updatedCartSport.length < (state.cart.folder_bonus?.bet_list_length || 0) ||
        listOptionValid.length < (state.cart.folder_bonus?.bet_list_length || 0)
      ) {
        state.cart.folder_bonus = {}
      }
    })
    .addCase(removeAllCartSport, (state) => {
      state.cart.pick_list = []
      state.cart.folder_bonus = {}
    })
    .addCase(addFolderBonusToCart, (state, action) => {
      if (state.cart.folder_bonus) {
        state.cart.folder_bonus.key = action.payload.bonus_folder_key
        state.cart.folder_bonus.rate = action.payload.bonus_folder_rate
        state.cart.folder_bonus.text = action.payload.bonus_folder_text
        state.cart.folder_bonus.bet_list_length = action.payload.bet_list_length
      }
    })
    .addCase(removeFolderBonusFromCart, (state, action) => {
      if (state.cart.folder_bonus && state.cart.folder_bonus.key === action.payload) {
        state.cart.folder_bonus = {}
      }
    })
    .addCase(removeAllItemInMatchAndAddNewItem, (state, action) => {
      const updatedCartSport = [...state.cart.pick_list].filter((item) => item.keyMatch !== action.payload.keyMatch)
      const status = action.payload.dateBet.status || 1
      updatedCartSport.push({ ...action.payload.dateBet, status })
      state.cart.pick_list = updatedCartSport

      const listOptionValid = updatedCartSport.filter(
        (item) => Number(item.rate) > (state.folderBonusQuery?.min_rate || 0)
      )
      if (
        updatedCartSport.length < (state.cart.folder_bonus?.bet_list_length || 0) ||
        listOptionValid.length < (state.cart.folder_bonus?.bet_list_length || 0)
      ) {
        state.cart.folder_bonus = {}
      }
    })
    .addCase(storeLimitSportBetting, (state, action) => {
      state.cart.bettingLimit = action.payload
    })
    .addCase(removeItemFromCartAndAddNewItem, (state, action) => {
      const updatedCartSport = state.cart.pick_list.filter(
        (item) => item.domesticData.idx !== action.payload.domesticIdx
      )
      updatedCartSport.push(action.payload.betItem)
      state.cart.pick_list = updatedCartSport
    })
    .addCase(updateAndLockItem, (state, action) => {
      const existingCartItemIndex = state.cart.pick_list.findIndex(
        (item) => item.domesticData.idx === action.payload.domesticIdx
      )
      if (existingCartItemIndex !== -1) {
        const updatedCartSport = [...state.cart.pick_list]
        updatedCartSport[existingCartItemIndex].isChanged = action.payload.isChanged
        updatedCartSport[existingCartItemIndex].isLocked = action.payload.isLocked
        updatedCartSport[existingCartItemIndex].newRate = action.payload.newRate
        const updateDomesticData = { ...updatedCartSport[existingCartItemIndex].domesticData }
        updatedCartSport[existingCartItemIndex].status = action.payload.status || 1
        updatedCartSport[existingCartItemIndex].domesticData = updateDomesticData
        if (!action.payload.isChanged) {
          updatedCartSport[existingCartItemIndex].rate = action.payload.newRate
        }

        state.cart.pick_list = updatedCartSport
      }
    })
    .addCase(storeFolderBonusQuery, (state, action) => {
      state.folderBonusQuery = action.payload
    })
    .addCase(setIsBetting, (state, action) => {
      state.isBetting = action.payload
    })
    .addCase(setIsRealTime, (state, action) => {
      state.isRealTime = action.payload
    })
})

export default sportReducer

import {
  ESportsCode,
  LIST_HANDICAP_ID,
  LIST_UNDER_OVER_ID,
  LIST_WIN_LOSE_ID,
  SportItemClient
} from 'src/types/sports.type'
import { DomesticList } from './DomesticList'
import { useSelectOptBettingSport } from '../hooks/useSelectOptBettingSport'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import { useDispatch } from 'react-redux'
import { resetDataBoardBet, setOpenBoardBetSport } from 'src/libs/stores/sport-search.reducer'
import cn from 'classnames'
import { LoadingIcon } from 'src/components/Icons'
import { toast } from 'react-toastify'
import { useState } from 'react'
import path from 'src/constants/path'
import { checkIsLockAllBetting } from 'src/libs/utils/sports.func'
import { config } from 'src/constants/config'

type Props = {
  sportData: SportItemClient
}

export const GroupSportItem = ({ sportData }: Props) => {
  const { domestic_client: listDomestic } = sportData
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { handleGetRateInfoList } = useSelectOptBettingSport()
  const { dataRateInfoList, isOpenBoardBetSport, isLoadingRateInfo } = useSportSearchStore()
  const count = sportData.count
  if (!listDomestic.length) {
    return
  }
  let isExistWinTieLose = false
  let isExistWinLose = false
  const sortListDomestic = [
    ...listDomestic.map((item) => {
      let order = 0
      if (LIST_WIN_LOSE_ID.includes(item.game_id)) {
        isExistWinLose = true
        order = 1
      } else if (LIST_HANDICAP_ID.includes(item.game_id)) {
        order = 2
      } else if (LIST_UNDER_OVER_ID.includes(item.game_id)) {
        order = 3
      }
      if (order === 0) {
        isExistWinTieLose = true
      }
      return {
        ...item,
        order
      }
    })
  ]
  sortListDomestic.sort((a, b) => {
    // if (a.game_type === b.game_type) {
    //   return Number(a.game_id) - Number(b.game_id)
    // }
    // return a.game_type - b.game_type
    return a.order - b.order
  })
  const domesticData = sortListDomestic[0]
  const isActiveSideRight =
    dataRateInfoList.length > 0 &&
    isOpenBoardBetSport &&
    dataRateInfoList[0].fixture_idx === domesticData.fixture_idx &&
    dataRateInfoList[0].league_idx === domesticData.league_idx &&
    dataRateInfoList[0].sports_idx === domesticData.sports_idx
      ? true
      : false

  const searchParams = new URLSearchParams(window.location.search)
  const sportsCode = searchParams.get('sportsCode')

  const isHiddenDetail =
    window.location.pathname === path.sports &&
    sportsCode &&
    [ESportsCode.Boxing, ESportsCode.Table_Tennis].includes(+sportsCode)

  let isLockMatch = false

  if (isExistWinTieLose && isExistWinLose) {
    if (
      sortListDomestic[0].status === 2 ||
      (checkIsLockAllBetting({
        rate1: sortListDomestic[0].rate1,
        rate2: sortListDomestic[0].rate2 && sortListDomestic[0].betid2 ? sortListDomestic[0].rate2 : undefined,
        rate3: sortListDomestic[0].rate3
      }) &&
        sortListDomestic[1].status === 2) ||
      checkIsLockAllBetting({
        rate1: sortListDomestic[1].rate1,
        rate2: sortListDomestic[1].rate2 && sortListDomestic[1].betid2 ? sortListDomestic[1].rate2 : undefined,
        rate3: sortListDomestic[1].rate3
      })
    ) {
      isLockMatch = true
    }
  }

  if (
    ((isExistWinTieLose || isExistWinLose) && sortListDomestic[0].status === 2) ||
    checkIsLockAllBetting({
      rate1: sortListDomestic[0].rate1,
      rate2: sortListDomestic[0].rate2 && sortListDomestic[0].betid2 ? sortListDomestic[0].rate2 : undefined,
      rate3: sortListDomestic[0].rate3
    })
  ) {
    isLockMatch = true
  }

  return (
    <div className='relative grid grid-cols-1 gap-1 bg-secondary pb-1.5'>
      <div className='flex items-center justify-between py-1.5 px-2 bg-black'>
        <span className='text-10 sm:text-12 md:text-14 bg-secondary px-1 py-1.5 rounded'>
          {sportData.start_date || ''}
        </span>
        {config.demoMode ? <span>fixture_idx: {sportData.idx}</span> : null}
        {!isHiddenDetail && (
          <button
            className={cn(
              `px-1 py-1.5 text-14 font-light rounded border bg-secondary flex items-center gap-2 duration-150`,
              {
                'text-white border-transparent': !isActiveSideRight,
                'text-primary border-primary ': isActiveSideRight,
                'flex items-center gap-2': isLoadingRateInfo
                // 'pointer-events-none': count <= 0
              }
            )}
            onClick={async () => {
              try {
                // if (count <= 0) {
                //   return
                // }
                if (domesticData.status === 2) {
                  return
                }
                setIsLoading(true)
                if (isLoadingRateInfo) {
                  return
                }
                if (isActiveSideRight) {
                  dispatch(setOpenBoardBetSport(!isOpenBoardBetSport))
                  dispatch(resetDataBoardBet())
                } else {
                  await handleGetRateInfoList({
                    domesticData,
                    sportData: sportData,
                    isForceOpen: true
                  })
                }
              } catch (error) {
                toast.error('실패. 나중에 다시 시도 해주십시오.')
                setIsLoading(false)
              } finally {
                setIsLoading(false)
              }
            }}
          >
            <LoadingIcon
              className={cn(' text-gray-2 fill-primary-2 text-11 sm:text-12 md:text-14 animate-spin', {
                'opacity-0 invisible size-0': !isLoading,
                'opacity-100 visible size-4': isLoading
              })}
            />
            <span className='text-11 sm:text-12 md:text-14'>{`+${count < 0 ? 0 : count} 추가 베팅옵션`}</span>
          </button>
        )}
      </div>
      <DomesticList sportData={sportData} listDomestic={sortListDomestic} />
      {(sportData.block_all || isLockMatch) && (
        <div className='absolute w-full h-full flex items-center justify-center bg-black-2/80'>
          <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-12'></img>
        </div>
      )}
    </div>
  )
}

import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { setForceHiddenRegister } from 'src/libs/stores/auth.reducer'

export const useGetPageSiteQuery = () => {
  const dispatch = useDispatch()
  const data = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' },
    onCompleted: (data) => {
      const metaTag = document.querySelector('meta[name="description"]')
      document.title = data?.PageSiteQuery?.title || ''
      metaTag?.setAttribute('content', data?.PageSiteQuery?.description)
      metaTag?.setAttribute('content', data?.PageSiteQuery?.description)

      dispatch(setForceHiddenRegister(!data?.PageSiteQuery?.is_user_join))
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })
  return data
}

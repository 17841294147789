import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import path from 'src/constants/path'
import { sportsApi } from 'src/libs/apis/axios/sports.api'
import {
  GET_EXCHANGE_SPORTS_QUERY,
  GET_FOLDER_BONUS_QUERY,
  GET_QUERY_RULES
} from 'src/libs/apis/graphql/queries/sport.query'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import {
  addDataRateInfoList,
  addDataToSearchListPure,
  resetDataSportSearch,
  setExchangeRateSport,
  setFetchAllApi,
  setFetchApiGetAllSports,
  setFilteredSports,
  setIsFirstTimeFetch,
  setOpenBoardBetSport,
  setRulesSport
} from 'src/libs/stores/sport-search.reducer'
import { transformDataSport } from 'src/libs/utils/sports.func'
import { ExchangeRateInfoQuery, FolderBonusQuery, ValidSelectOptionQuery } from 'src/types/sports.type'
import { useCommonStore } from './../../../libs/hooks/store/useCommonStore'
import { getAccessTokenFromLS, getAccessTokenFromSS, getTokenDynamic } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'
import { storeFolderBonusQuery } from 'src/libs/stores/sport.reducer'
const DEFAULT_PAGE_LIMIT = 20

export const useSports = () => {
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const { dataSportSearch, isOpenBoardBetSport, isFirstTimeFetch, isLoadingRateInfo, isLoadingSports, filteredSports } =
    useSportSearchStore()
  const { categoriesSports } = useCommonStore()
  const [openMenuCategory, setOpenMenuCategory] = useState(false)
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const user = useSelector((state: RootState) => state.auth.user)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])
  const dispatch = useDispatch()
  const [paramSearch] = useSearchParams()
  const [isLoadingChangePagination, setIsLoadingChangePagination] = useState(false)
  const sportsCodeParams = paramSearch.get('sportsCode')
  const nationCodeParams = paramSearch.get('nationCode')
  const leagueCodeParams = paramSearch.get('leagueCode')
  const isFavoriteParams = paramSearch.get('isFavorite')
  const isSortBy24hParams = paramSearch.get('isSortBy24h')
  const isSortByTomorrowParams = paramSearch.get('isSortByTomorrow')
  const isSortByLeagueParams = paramSearch.get('isSortByLeague')
  const pageParams = paramSearch.get('page')
  const [pagination, setPagination] = useState<{
    current_page: number
    total: number
  }>({
    current_page: pageParams ? +pageParams : 1,
    total: 1
  })

  const [getRulesSports] = useLazyQuery<ValidSelectOptionQuery>(GET_QUERY_RULES, {
    context: { apiName: 'sports' },
    onCompleted: (data) => {
      if (data && data.ValidSelectOptionQuery.length) {
        dispatch(setRulesSport(data.ValidSelectOptionQuery))
      }
    }
  })

  const [getExchangeRateSports] = useLazyQuery<
    ExchangeRateInfoQuery,
    {
      type: string
    }
  >(GET_EXCHANGE_SPORTS_QUERY, {
    context: { apiName: 'sports' },
    onCompleted: (data) => {
      if (data && data.ExchangeRateInfoQuery.length) {
        dispatch(setExchangeRateSport(data.ExchangeRateInfoQuery))
      }
    }
  })

  const { data: dataFolderBonus, loading: isLoadingDataFolderBonus } = useQuery<FolderBonusQuery>(
    GET_FOLDER_BONUS_QUERY,
    {
      context: {
        apiName: 'sports'
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        dispatch(storeFolderBonusQuery({ folderBonusQuery: data.FolderBonusQuery }))
      }
    }
  )
  const navigate = useNavigate()

  const categoriesSportsWithAllOpts = useMemo(() => {
    return [
      {
        count: categoriesSports.reduce((sum, cur) => sum + cur.count, 0),
        imgPath: '/categoryAll.png',
        nationList: [],
        sportsCode: 0,
        sportsName: 'All',
        sportsNameEn: 'All'
      },
      ...categoriesSports
    ]
  }, [categoriesSports])

  const handleToggleMenuCategory = () => {
    setOpenMenuCategory((prev) => !prev)
  }

  const handleInitRules = async () => {
    document.getElementById('main-content')?.scrollTo({
      top: 0
    })
    await getRulesSports()
    await getExchangeRateSports({
      variables: {
        type: 'cross'
      }
    })
  }

  async function handleGetInfoSportsPage({ page = 1 }: { page: number }) {
    let isCancel = false
    try {
      const paramsFilter = {
        limit: DEFAULT_PAGE_LIMIT,
        page: page,
        sportsCode: sportsCodeParams || '',
        nationCode: nationCodeParams || '',
        leagueCode: leagueCodeParams || '',
        isFavorite: filteredSports.isFavorite,
        isSortBy24h: filteredSports.isSortBy24h,
        isSortByTomorrow: filteredSports.isSortByTomorrow,
        isSortByLeague: filteredSports.isSortByLeague
      }
      dispatch(setFetchApiGetAllSports(true))
      // dispatch(setOpenBoardBetSport(false))
      const res = await sportsApi.getAllSports({
        params: paramsFilter,
        options: {
          signal: abortControllerRef.current.signal,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      })
      if (res.data) {
        dispatch(
          addDataToSearchListPure({
            sportList: res.data.data.detailInfo.data.map(transformDataSport).filter((sportItem) => sportItem !== null)
          })
        )
        setPagination({
          current_page: page,
          total: res.data ? res.data.data.detailInfo.total : 1
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.code === 'ERR_CANCELED') {
        isCancel = true
        return
      }
      dispatch(
        addDataToSearchListPure({
          sportList: []
        })
      )
      toast.error('문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
      dispatch(setFetchAllApi(false))
      isFirstTimeFetch && dispatch(setIsFirstTimeFetch(false))
    } finally {
      dispatch(setFetchAllApi(false))
      isFirstTimeFetch && dispatch(setIsFirstTimeFetch(false))
      !isCancel && setIsLoadingChangePagination(false)
    }
  }

  const handleChangePaginationSport = async (page: number) => {
    setIsLoadingChangePagination(true)
    window.history.pushState(
      null,
      '',
      `${path.sports}?page=${page}${sportsCodeParams ? '&sportsCode=' + sportsCodeParams : ''}${nationCodeParams ? '&nationCode=' + nationCodeParams : ''}${leagueCodeParams ? '&leagueCode=' + leagueCodeParams : ''}${filteredSports.isFavorite ? '&isFavorite=' + filteredSports.isFavorite : ''}${filteredSports.isSortBy24h ? '&isSortBy24h=' + filteredSports.isSortBy24h : ''}${filteredSports.isSortByTomorrow ? '&isSortByTomorrow=' + filteredSports.isSortByTomorrow : ''}${filteredSports.isSortByLeague ? '&isSortByLeague=' + filteredSports.isSortByLeague : ''}`
    )
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    await handleGetInfoSportsPage({ page })
  }
  const handleChangeCategory = ({ sportsCode }: { sportsCode: number }) => {
    navigate({
      pathname: path.sports,
      search: '?' + `sportsCode=${sportsCode}`
    })
    dispatch(
      addDataToSearchListPure({
        sportList: []
      })
    )
    dispatch(
      addDataRateInfoList({
        rateInfoList: []
      })
    )
    dispatch(
      setFilteredSports({
        isFavorite: false,
        isSortBy24h: false,
        isSortByTomorrow: false,
        isSortByLeague: false
      })
    )
    dispatch(setOpenBoardBetSport(false))
  }

  useEffect(() => {
    setIsLoadingChangePagination(true)
    handleInitRules()
    handleGetInfoSportsPage({ page: pageParams ? Number(pageParams) : 1 })
    return () => {
      abortControllerRef.current.abort()
      dispatch(resetDataSportSearch())
      dispatch(setIsFirstTimeFetch(false))
    }
  }, [])

  useEffect(() => {
    if (Number(sportsCodeParams) !== 0) {
      const categoryTarget = categoriesSports.find((item) => item.sportsCode === Number(sportsCodeParams))
      if (!categoryTarget) {
        return
      }
      if (categoryTarget?.maintenance) {
        return
      }
    }
    if (
      !isFirstTimeFetch &&
      (Number(sportsCodeParams) === 0 ||
        sportsCodeParams !== null ||
        nationCodeParams !== null ||
        leagueCodeParams !== null)
    ) {
      // window.history.pushState(
      //   null,
      //   '',
      //   `${path.sports}?page=${1}${sportsCodeParams && sportsCodeParams !== '0' ? '&sportsCode=' + sportsCodeParams : ''}${nationCodeParams ? '&nationCode=' + nationCodeParams : ''}${leagueCodeParams ? '&leagueCode=' + leagueCodeParams : ''}${filteredSports.isFavorite ? '&isFavorite=' + filteredSports.isFavorite : '&isFavorite=false'}${filteredSports.isSortBy24h ? '&isSortBy24h=' + filteredSports.isSortBy24h : ''}${filteredSports.isSortByTomorrow ? '' + filteredSports.isSortByTomorrow : ''}${filteredSports.isSortByLeague ? '&isSortByLeague=' + filteredSports.isSortByLeague : ''}`
      // )
      window.history.pushState(
        null,
        '',
        `${path.sports}?page=${1}${sportsCodeParams && sportsCodeParams !== '0' ? '&sportsCode=' + sportsCodeParams : ''}${nationCodeParams ? '&nationCode=' + nationCodeParams : ''}${leagueCodeParams ? '&leagueCode=' + leagueCodeParams : ''}${filteredSports.isFavorite ? '&isFavorite=' + filteredSports.isFavorite : ''}${filteredSports.isSortBy24h ? '&isSortBy24h=' + filteredSports.isSortBy24h : ''}${filteredSports.isSortByTomorrow ? '' + filteredSports.isSortByTomorrow : ''}${filteredSports.isSortByLeague ? '&isSortByLeague=' + filteredSports.isSortByLeague : ''}`
      )
      setIsLoadingChangePagination(true)
      abortControllerRef.current.abort()
      abortControllerRef.current = new AbortController()
      handleGetInfoSportsPage({ page: 1 })
    }
  }, [sportsCodeParams, nationCodeParams, leagueCodeParams])

  useEffect(() => {
    window.history.pushState(
      null,
      '',
      `${path.sports}?page=${1}${sportsCodeParams && sportsCodeParams !== '0' ? '&sportsCode=' + sportsCodeParams : ''}${nationCodeParams ? '&nationCode=' + nationCodeParams : ''}${leagueCodeParams ? '&leagueCode=' + leagueCodeParams : ''}${filteredSports.isFavorite ? '&isFavorite=' + filteredSports.isFavorite : ''}${filteredSports.isSortBy24h ? '&isSortBy24h=' + filteredSports.isSortBy24h : ''}${filteredSports.isSortByTomorrow ? '&isSortByTomorrow=' + filteredSports.isSortByTomorrow : ''}${filteredSports.isSortByLeague ? '&isSortByLeague=' + filteredSports.isSortByLeague : ''}`
    )
    setIsLoadingChangePagination(true)
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    handleGetInfoSportsPage({ page: 1 })
  }, [
    filteredSports.isFavorite,
    filteredSports.isSortBy24h,
    filteredSports.isSortByTomorrow,
    filteredSports.isSortByLeague
  ])

  useEffect(() => {
    const filteredSportsFromParams = {
      isFavorite: isFavoriteParams === 'true',
      isSortBy24h: isSortBy24hParams === 'true',
      isSortByTomorrow: isSortByTomorrowParams === 'true',
      isSortByLeague: isSortByLeagueParams === 'true'
    }
    dispatch(setFilteredSports(filteredSportsFromParams))
  }, [isFavoriteParams, isSortBy24hParams, isSortByTomorrowParams, isSortByLeagueParams])

  return {
    sportList: dataSportSearch || [],
    // categoriesSports: categoriesSports.slice(0, 3),
    categoriesSports: categoriesSportsWithAllOpts,
    dataFolderBonus,
    isFirstTimeFetch,
    isOpenBoardBetSport,
    isLoadingRateInfo,
    isLoadingSports,
    isLoadingChangePagination,
    isLoadingDataFolderBonus,
    pagination,
    pageSize: DEFAULT_PAGE_LIMIT,
    sportsCodeActive: sportsCodeParams ? Number(sportsCodeParams) : 0,
    openMenuCategory,
    categoriesPure: categoriesSports,
    handleToggleMenuCategory,
    handleChangePaginationSport,
    handleChangeCategory
  }
}

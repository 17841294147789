import { useDispatch, useSelector } from 'react-redux'
import { setOpenAuthModal } from 'src/libs/stores/auth.reducer'
import { RootState } from 'src/libs/utils/store'

interface SpecialButtonProps {
  item: any
  index: number
  type: 'sidebar' | 'navbar'
  chooseGame: () => void
}

const SpecialButton = ({ item, index, type, chooseGame }: SpecialButtonProps) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.auth.user)

  if (type === 'sidebar') {
    return (
      <button
        className={`flex w-full text-sm items-center py-3 gap-6 font-normal hover:bg-secondary-1 rounded-md bright-icon  px-2 }`}
        onClick={() => {
          if (!user) {
            dispatch(setOpenAuthModal('login'))
            return
          }
          chooseGame()
        }}
        key={index}
      >
        <img
          src={item.image}
          alt={item?.value}
          title={item?.value}
          width='16'
          height='16'
          onError={(e) => {
            e.currentTarget.onerror = null
            e.currentTarget.src = '/assets/images/icons/note (1).png'
          }}
        />
        {item?.value}
      </button>
    )
  }

  return (
    <button
      onClick={() => {
        if (!user) {
          dispatch(setOpenAuthModal('login'))
          return
        }
        chooseGame()
      }}
      className='font-bold text-12 2xl:text-[24px] hover:text-primary'
      key={index}
    >
      {item.value}
    </button>
  )
}

export default SpecialButton

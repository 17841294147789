import { ReactNode } from 'react'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import { DomesticWithKeyMatch, LiveSportItemClient } from 'src/types/live-sports.type'

type Props = {
  domesticData: DomesticWithKeyMatch
  isLastItem?: boolean
  sportData: Partial<LiveSportItemClient>
  render?: ({
    isActiveSideRight,
    isOpenBoardBetSport
  }: {
    isActiveSideRight: boolean
    isOpenBoardBetSport: boolean
  }) => ReactNode
}

export const DomesticItem = ({ domesticData, isLastItem = false, render, sportData }: Props) => {
  const { dataRateInfoList, isOpenBoardBetSport } = useSportSearchStore()
  const isActiveSideRight =
    dataRateInfoList.length > 0 &&
    isOpenBoardBetSport &&
    dataRateInfoList[0].fixture_idx === domesticData.fixture_idx &&
    dataRateInfoList[0].league_idx === domesticData.league_idx &&
    dataRateInfoList[0].sports_idx === domesticData.sports_idx
      ? true
      : false
  return (
    <>
      <div key={domesticData.idx} className='grid grid-cols-3 items-center justify-center py-4 bg-sports-black-3'>
        <div className={'flex flex-col gap-1 items-center justify-center h-[60px] opacity-80'}>
          {/* <img src={'/test-logo-team.png'} alt={domesticData.home_team} className='size-10' /> */}
          <span className='max-w-20 md:max-w-[160px] whitespace-nowrap line-clamp-1 text-16'>
            {domesticData.home_team}
          </span>
        </div>
        <div className='flex flex-col gap-1 items-center justify-center h-[60px] opacity-80'>
          <span className='text-12'>{sportData.start_date}</span>
          <span className='text-12'>VS</span>
        </div>
        <div className={'flex flex-col gap-1 items-center justify-center h-[60px] opacity-80'}>
          {/* <img src={'/test-logo-team.png'} alt={domesticData.away_team} className='size-10' /> */}
          <span className='max-w-20 md:max-w-[160px] whitespace-nowrap line-clamp-1 text-16'>
            {domesticData.away_team}
          </span>
        </div>
      </div>
      {render && render({ isActiveSideRight, isOpenBoardBetSport })}
    </>
  )
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'

export const useLiveSportSearchStore = () => {
  const {
    dataRateInfoList,
    dataRateInfoListPure,
    dataLiveSportSearch,
    dataLiveSportSearchPure,
    isLoadingRateInfo,
    isLoadingLiveSports,
    isOpenBoardBetSport,
    isFirstTimeFetch,
    exchangeRateInfoList,
    eventIdLiveSport
  } = useSelector((state: RootState) => state.liveSportSearch)

  const objExchangeRateInfo = useMemo(() => {
    return exchangeRateInfoList.reduce<{ [key: string | number]: number }>((acc, cur) => {
      if (!acc[cur.sports_code]) {
        acc[cur.sports_code] = cur.rate_deduct
      }
      return acc
    }, {})
  }, [exchangeRateInfoList])

  const sportData = useMemo(() => {
    return dataLiveSportSearchPure.find((item) => item.idx === +eventIdLiveSport)
  }, [dataLiveSportSearchPure, eventIdLiveSport])

  return {
    dataLiveSportSearchPure,
    dataRateInfoList,
    dataRateInfoListPure,
    dataLiveSportSearch,
    isLoadingLiveSports,
    isLoadingRateInfo,
    isOpenBoardBetSport,
    isFirstTimeFetch,
    objExchangeRateInfo,
    sportData
  }
}

import { BoardItem12 } from 'src/components/BoardItemLayout/BoardItem12'
import { BoardItem12AndUO } from 'src/components/BoardItemLayout/BoardItem12AndUO'
import { BoardItem1VS2 } from 'src/components/BoardItemLayout/BoardItem1VS2'
import { BoardItem1XX212 } from 'src/components/BoardItemLayout/BoardItem1XX212'
import { BoardItemHalf } from 'src/components/BoardItemLayout/BoardItemHalf'
import { BoardItemHandicap } from 'src/components/BoardItemLayout/BoardItemHandicap'
import { BoardItemHTFT } from 'src/components/BoardItemLayout/BoardItemHTFT'
import { BoardItemLine1VS2 } from 'src/components/BoardItemLayout/BoardItemLine1VS2'
import { BoardItemNeither } from 'src/components/BoardItemLayout/BoardItemNeither'
import { BoardItemOddEven } from 'src/components/BoardItemLayout/BoardItemOddEven'
import { BoardItemOneScore } from 'src/components/BoardItemLayout/BoardItemOneScore'
import { BoardItemUnderOver } from 'src/components/BoardItemLayout/BoardItemUnderOver'
import { BoardItemYesNo } from 'src/components/BoardItemLayout/BoardItemYesNo'
import {
  DomesticWithKeyMatch,
  LIST_12_AND_UO_ID,
  LIST_1X2X12_ID,
  LIST_HALF_ID,
  LIST_HANDICAP_ID,
  LIST_HTFT_ID,
  LIST_NEITHER_ID,
  LIST_NO_GOAL_ID,
  LIST_ODD_EVEN_ID,
  LIST_SCORE_ID,
  LIST_UNDER_OVER_ID_ALL,
  LIST_WIN_LOSE_ID,
  LIST_WIN_TIE_LOSE_ID,
  LIST_WIN_TIE_LOSE_LINE_ID,
  LIST_YES_NO_ID
} from 'src/types/sports.type'

interface Props {
  domesticData: DomesticWithKeyMatch
}

export const BoardBetItem = ({ domesticData }: Props) => {
  const renderByGameType = ({ gameId }: { gameType: number; gameId: string }) => {
    const gameTypeMapping = {
      1: <BoardItem1VS2 domesticData={domesticData} wrapperClassNames='col-span-12' />,
      2: <BoardItemHandicap domesticData={domesticData} wrapperClassNames='col-span-12' />,
      3: <BoardItemUnderOver domesticData={domesticData} wrapperClassNames='col-span-12' />,
      4: <BoardItem12 domesticData={domesticData} wrapperClassNames='col-span-12' />,
      5: <BoardItemYesNo domesticData={domesticData} wrapperClassNames='col-span-12' />,
      6: <BoardItemNeither domesticData={domesticData} wrapperClassNames='col-span-12' />,
      7: <BoardItemNeither domesticData={domesticData} wrapperClassNames='col-span-12' />,
      8: <BoardItemOneScore domesticData={domesticData} wrapperClassNames='col-span-4' />,
      9: <BoardItemLine1VS2 domesticData={domesticData} wrapperClassNames='col-span-12' />,
      10: <BoardItemOddEven domesticData={domesticData} wrapperClassNames='col-span-12' />,
      11: <BoardItemHalf domesticData={domesticData} wrapperClassNames='col-span-12' />,
      12: <BoardItem1XX212 domesticData={domesticData} wrapperClassNames='col-span-12' />,
      13: <BoardItemHTFT domesticData={domesticData} wrapperClassNames='col-span-4' />,
      14: <BoardItem12AndUO domesticData={domesticData} wrapperClassNames='col-span-6' />
    }
    if (LIST_WIN_TIE_LOSE_ID.includes(gameId)) {
      return gameTypeMapping[1]
    } else if (LIST_HANDICAP_ID.includes(gameId)) {
      return gameTypeMapping[2]
    } else if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return gameTypeMapping[3]
    } else if (LIST_WIN_LOSE_ID.includes(gameId)) {
      return gameTypeMapping[4]
    } else if (LIST_YES_NO_ID.includes(gameId)) {
      return gameTypeMapping[5]
    } else if (LIST_NO_GOAL_ID.includes(gameId)) {
      return gameTypeMapping[6]
    } else if (LIST_NEITHER_ID.includes(gameId)) {
      return gameTypeMapping[7]
    } else if (LIST_SCORE_ID.includes(gameId)) {
      return gameTypeMapping[8]
    } else if (LIST_WIN_TIE_LOSE_LINE_ID.includes(gameId)) {
      return gameTypeMapping[9]
    } else if (LIST_ODD_EVEN_ID.includes(gameId)) {
      return gameTypeMapping[10]
    } else if (LIST_HALF_ID.includes(gameId)) {
      return gameTypeMapping[11]
    } else if (LIST_1X2X12_ID.includes(gameId)) {
      return gameTypeMapping[12]
    } else if (LIST_HTFT_ID.includes(gameId)) {
      return gameTypeMapping[13]
    } else if (LIST_12_AND_UO_ID.includes(gameId)) {
      return gameTypeMapping[14]
    }
  }

  return renderByGameType({ gameType: domesticData.game_type, gameId: domesticData.game_id })
}

import { gql } from '@apollo/client'

const SiteInfoQuery = gql`
  query SiteInfoQuery {
    SiteInfoQuery {
      min_deposit
      deposit_text
      min_withraw
      withraw_text
      validation_withraw {
        required
        min
      }
      validation_deposit {
        required
        min
      }
      recharge_config {
        rc_rules
        rc_max_bonus_first_time_sports_recharge
        rc_max_bonus_sports_recharge
        rc_max_bonus_first_time_casino_recharge
        rc_max_bonus_casino_recharge
        rc_exchange_rules
        rc_enable_bonus
        rc_manual_recharge
        rc_bonus {
          sports {
            no_bonus {
              title
              description
            }
            participate_bonus {
              title
              description
            }
            new_member_bonus {
              title
              description
            }
            bonus {
              title
              description
            }
            sudden_bonus {
              title
              description
            }
          }
          casino_slot {
            no_bonus {
              title
              description
            }
            participate_bonus {
              title
              description
            }
            new_member_bonus {
              title
              description
            }
            sports_bonus {
              title
              description
            }
            bonus {
              title
              description
            }
            sudden_bonus {
              title
              description
            }
            slot_bonus {
              title
              description
            }
          }
        }
      }
      sports_config_text
      casino_config_text
      withdraw_config {
        wc_rules
        wc_manual_withdraw
        wc_exchange_rules
      }
      game_config {
        siEnableGamesConfig
        siEnableGamesConfigNotice
        casino {
          enable
        }
        slot {
          enable
        }
      }
      roulette_rules {
        member_roulette
      }
      transfer_coin_url
    }
  }
`

const InfoQuery = gql`
  query InfoQuery {
    InfoQuery {
      recharge_config {
        validation_deposit {
          required
        }
        rc_rules
        rc_max_bonus_first_time_sports_recharge
        rc_max_bonus_sports_recharge
        rc_max_bonus_first_time_casino_recharge
        rc_max_bonus_casino_recharge
        rc_enable_bonus
        rc_max_bonus_first_time_poker_recharge
        rc_max_bonus_poker_recharge
        rc_amount_no_bonus
        rc_auto_bonus
        rc_manual_recharge
        rc_enable_recharge
        rc_enable_config_bonus
        rc_enable_thousand_money
        rc_exchange_rules
        rc_bonus {
          casino_slot {
            key
            title
            description
            type
            color
          }
          sports {
            key
            type
            title
            description
            color
          }
        }
      }
      withdraw_config {
        wc_rules
        wc_manual_withdraw
        wc_exchange_rules
      }
    }
  }
`

export { SiteInfoQuery, InfoQuery }

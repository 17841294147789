import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { pathMiniGameType } from 'src/constants/path'
import { useDocumentVisibility } from 'src/libs/hooks/useDocumentVisibility'
import { usePusher } from 'src/providers/PusherProvider'

type Props = {
  callback: () => Promise<void>
  refetch: () => Promise<void>
  gameType?: string
}

export const useEventMiniGame = ({ callback, gameType, refetch }: Props) => {
  const pusher = usePusher()
  const location = useLocation()
  useDocumentVisibility({ cbActiveTab: callback })

  useEffect(() => {
    const channel = pusher?.subscribe('minigame-result-channel')
    channel?.bind('minigame-result-event', async (data: { game: string }) => {
      const currentGameType = gameType || pathMiniGameType[location.pathname]
      if (currentGameType === data.game) {
        await refetch()
      }
    })

    return () => {
      channel?.unbind('minigame-result-event')
      pusher?.unsubscribe('minigame-result-channel')
    }
  }, [location.pathname, pusher, gameType])

  useEffect(() => {
    const channel = pusher?.subscribe('minigame-new-round-channel')
    channel?.bind('minigame-new-round-event', async (data: { game: string }) => {
      const currentGameType = gameType || pathMiniGameType[location.pathname]
      if (currentGameType === data.game) {
        await callback()
      }
    })

    return () => {
      channel?.unbind('minigame-new-round-event')
      pusher?.unsubscribe('minigame-new-round-channel')
    }
  }, [location.pathname, pusher, gameType, callback])
}

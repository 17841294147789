/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import cn from 'classnames'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { ImClubs, ImDiamonds, ImHeart, ImSpades } from 'react-icons/im'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { pathMiniGameType } from 'src/constants/path'
import { BET_PARSING_CASINO_MUTATION } from 'src/libs/apis/graphql/mutations/mini_game.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { GET_LIMIT_PARSING_CASINO } from 'src/libs/apis/graphql/queries/game.query'
import { GET_MENU_MINI_GAME } from 'src/libs/apis/graphql/queries/mini_game.query'
import {
  GET_PARSING_CASINO_GAME_BET_HISTORY,
  GET_PARSING_CASINO_MENU
} from 'src/libs/apis/graphql/queries/parsing-casino.query'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { setSportsMoney } from 'src/libs/stores/auth.reducer'
import { removeAllCartMiniGame, removeItemFromCartMiniGame } from 'src/libs/stores/minigame.reducer'
import { client } from 'src/libs/utils/apollo'
import { getTokenDynamic } from 'src/libs/utils/auth'
import { transformCartMiniGameToPickList } from 'src/libs/utils/minigame.func'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { usePusher } from 'src/providers/PusherProvider'
import { PaginationType } from 'src/types/common.type'
import {
  BetMiniGameRequest,
  MenuMiniGameList,
  ParsingCasinoList,
  ResultParsingCasinoGameHistory
} from 'src/types/minigame.type'

const MINI_GAME_CART_BET = 1
const MINI_GAME_CART_HISTORY = 2

export const textRender: Record<string, ReactNode> = {
  even: '짝',
  odd: '홀',
  under: '오버',
  over: '언더',
  heart: <ImHeart size={16} />,
  spade: <ImSpades size={16} />,
  club: <ImClubs size={16} />,
  diamond: <ImDiamonds size={16} />,
  tie: 'TIE',
  banker: 'B',
  player: 'P',
  banker_pair: 'BB',
  player_pair: 'PP',
  go: 'GO',
  stop: 'STOP',
  red: '레드',
  black: '블랙'
}

export type LimitMiniGame = {
  BettingLimitsParsingCasino: {
    max_bet_amount?: number
    min_bet_amount?: number
    max_bet_payout?: number
    max_payout?: number
    percent_distribution?: number
  }
}

type LimitMiniGameVar = {
  game?: string
}

export default function ParsingCasinoCart() {
  const dispatch = useDispatch()
  const pusher = usePusher()

  const { cart } = useCartMiniGameStore()
  const user = useSelector((state: RootState) => state.auth.user)

  const [crashBet, setCrashBet] = useState(0)
  const [tab, setTab] = useState(MINI_GAME_CART_BET)
  const [query, setQuery] = useState<PaginationType>({ offset: 0, limit: 10, total: 0 })
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])
  const [menu, setMenu] = useState<{ [key: string]: string }>({})

  const [refreshMemberInfo] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(setSportsMoney(data?.MemberQuery?.mMoney))
    }
  })

  const [getLimitMiniGame, { data: limitData }] = useLazyQuery<LimitMiniGame, LimitMiniGameVar>(
    GET_LIMIT_PARSING_CASINO,
    {
      context: { apiName: 'parsing-casino' },
      fetchPolicy: 'network-only'
    }
  )

  const limit = limitData?.BettingLimitsParsingCasino || {
    max_bet_amount: 0,
    min_bet_amount: 0,
    max_bet_payout: 0,
    max_payout: 0,
    percent_distribution: 0
  }

  const { data: parsingCasinoBetData, refetch: refetchParsingCasinoBetData } = useQuery<ResultParsingCasinoGameHistory>(
    GET_PARSING_CASINO_GAME_BET_HISTORY,
    {
      variables: {
        limit: 3,
        page: 1,
        // @ts-ignore
        category: ''
      },
      context: { apiName: 'parsing-casino' },
      notifyOnNetworkStatusChange: true
    }
  )

  const totalBetRate = useMemo(() => {
    return cart.pick_list.reduce((sum, item) => {
      return sum + Number(item.miniGameItem.rate)
    }, 0)
  }, [JSON.stringify(cart.pick_list)])

  const totalBetLimit = Number(totalBetRate.toFixed(2))
  const formattedTotalBetRate = totalBetLimit?.toFixed(2)
  const totalMoney = (crashBet * Number(formattedTotalBetRate)).toFixed(0)

  const [betParsingCasinoMutation, { loading: loadingParsingCasino }] = useMutation<any, BetMiniGameRequest>(
    BET_PARSING_CASINO_MUTATION
  )

  const { data: miniGameMenu = { MenuMiniGameQuery: [] } } = useQuery<MenuMiniGameList>(GET_MENU_MINI_GAME, {
    context: {
      apiName: 'mini-game'
    }
  })

  const { data: parsingCasinoMenu = { MenuParsingCasinoQuery: [] } } = useQuery<ParsingCasinoList>(
    GET_PARSING_CASINO_MENU,
    {
      context: {
        apiName: 'parsing-casino'
      }
    }
  )
  useEffect(() => {
    if (miniGameMenu?.MenuMiniGameQuery.length || parsingCasinoMenu?.MenuParsingCasinoQuery.length) {
      const parseGameMenu = miniGameMenu.MenuMiniGameQuery.map((item) => {
        if (!item.MiniGames.length) {
          return {
            no: item.mgpNo,
            name: item.mgpName,
            nameEn: item.mgpNameEn,
            path: 'item.mgpNameEn',
            link: 'item.mgpNameEn',
            subMenu: []
          }
        }
        return {
          no: item.mgpNo,
          name: item.mgpName,
          nameEn: item.mgpNameEn,
          path: item.MiniGames[0].mgPath,
          link: item.MiniGames[0].mgFrameLink,
          subMenu: item.MiniGames.map((subItem) => ({
            no: subItem.mgNo,
            name: subItem.mgName,
            nameEn: subItem.mgNameEn,
            path: subItem.mgPath,
            link: subItem.mgFrameLink
          }))
        }
      })
      const parseCasinoMenu = parsingCasinoMenu.MenuParsingCasinoQuery.map((item) => {
        if (!item.Providers.length) {
          return {
            no: item.pcpNo,
            name: item.pcpName,
            nameEn: item.pcpNameEn,
            path: 'item.pcpNameEn',
            link: 'item.pcpNameEn',
            subMenu: []
          }
        }
        return {
          no: item.pcpNo,
          name: item.pcpName,
          nameEn: item.pcpNameEn,
          path: item.Providers[0]?.pcPath?.replace('/mini/parsing-casino/', '/mini/'),
          link: item.Providers[0]?.pcFrameLink,
          subMenu: item.Providers.map((subItem) => ({
            no: subItem.pcNo,
            name: subItem.pcName,
            nameEn: subItem.pcNameEn,
            path: (subItem.pcPath || '').replace('/mini/parsing-casino/', '/mini/'),
            link: subItem.pcFrameLink
          }))
        }
      })
      setMenu(
        [...parseGameMenu, ...parseCasinoMenu]
          .map((item) => {
            return item.subMenu
          })
          .flat()
          .reduce((acc, curr) => {
            return { ...acc, [curr.path as string]: curr.name }
          }, {})
      )
    }
  }, [miniGameMenu, parsingCasinoMenu])
  const gameName = menu[location.pathname] || ''

  const handleChangeTotalBet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxBetAmount = limit?.max_bet_amount || 0
    const availableMoney = user?.mMoney || 0
    const value = Number(e.target.value.replace(/\D/g, ''))

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (value > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(value))
    }
  }

  const addExtraBet = (value: number) => {
    const maxBetAmount = limit?.max_bet_amount || 0
    const minBetAmount = limit?.min_bet_amount || 0

    if (Math.min(value, user?.mMoney) < minBetAmount) {
      return
    }

    const availableMoney = user?.mMoney || 0
    const newBetAmount = crashBet + value

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (newBetAmount > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(newBetAmount))
    }
  }

  const handleAddMaxBet = (value: number) => {
    if (totalBetLimit * value > (limit?.max_bet_payout ?? 0)) {
      return Math.floor(Number((limit?.max_bet_payout ?? 0) / totalBetLimit))
    } else {
      return Number(value)
    }
  }

  const [minigameName, setMiniGameName] = useState('')

  const nameMiniGame = () => {
    return pathMiniGameType[location.pathname] || ''
  }

  useEffect(() => {
    setMiniGameName(nameMiniGame())
    getLimitMiniGame({
      variables: {
        game: nameMiniGame()
      }
    })
  }, [getLimitMiniGame, location.pathname])

  const handleBetting = async () => {
    await betParsingCasinoMutation({
      context: { apiName: 'parsing-casino' },
      variables: cart.pick_list.map((item) => {
        return transformCartMiniGameToPickList({ betItem: item, betAmount: crashBet, gameType: minigameName })
      })[0],
      onCompleted: async () => {
        toast.success('배팅 성공')
        dispatch(removeAllCartMiniGame())
        setCrashBet(0)
        await refreshMemberInfo()
        // dispatch(setRefetchHistoryBetting(true))

        client.refetchQueries({
          include: [GET_PARSING_CASINO_GAME_BET_HISTORY]
        })
        await refetchParsingCasinoBetData()
      },
      onError: (error) => {
        toast.error(error?.message ?? '배팅 실패')
      }
    })
  }

  useEffect(() => {
    return () => {
      setCrashBet(0)
    }
  }, [window.location.pathname])

  useEffect(() => {
    const channel = pusher?.subscribe('parsing-casino-result-channel')
    channel?.bind('parsing-casino-result-event', async () => {
      await refetchParsingCasinoBetData()
    })

    return () => {
      channel?.unbind('parsing-casino-result-event')
      pusher?.unsubscribe('parsing-casino-result-channel')
    }
  }, [])

  // useEffect(() => {
  //   if (user) {
  //     refetchMiniGameBetData()
  //   }
  // }, [user])

  const renderMiniGameCart = () => {
    return cart.pick_list.length > 0 ? (
      cart.pick_list.map((item) => {
        const { miniGameItem, selectedKeyItem } = item
        return (
          <div key={selectedKeyItem} className='bg-[#14161b] rounded relative'>
            <div className='p-2 pt-4 flex flex-col relative'>
              <button
                type='button'
                onClick={() => {
                  dispatch(
                    removeItemFromCartMiniGame({
                      selectedKeyItem
                    })
                  )
                }}
                className='absolute top-4 right-2 font-bold'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  x='0px'
                  y='0px'
                  width='15'
                  height='15'
                  viewBox='0 0 24 24'
                  fill='#ffffff'
                >
                  <path d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z'></path>
                </svg>
              </button>
              <div className='flex'>
                <div className='flex flex-auto'>
                  <p className='text-white text-12'>{`${miniGameItem.text}${miniGameItem.category ? ' ' + miniGameItem.category : ''}`}</p>
                </div>
              </div>
              <div className='flex flex-auto'>
                <p className='text-white text-12'>{`${gameName}-${miniGameItem.transId.split('_').length > 1 ? miniGameItem.transId.split('_')[1] : miniGameItem.transId} 회차`}</p>
              </div>
              <div className='flex items-center justify-between'>
                <p className=' text-[#d65708] text-12 font-bold'>{gameName}</p>
                <span className='text-primary text-12'>{miniGameItem.rate}</span>
              </div>
            </div>
          </div>
        )
      })
    ) : (
      <>
        <p className='text-12 text-center mx-auto pt-2 pb-4'>선택된 배팅내역이 없습니다.</p>
      </>
    )
  }
  return (
    <>
      <div className='w-full mt-4'>
        <div className='flex justify-between'>
          <button
            className={`w-1/2 h-10 font-normal hover:text-primary-2 hover:border-b hover:border-b-primary-2 ${tab === MINI_GAME_CART_BET ? 'text-primary-2 border-b border-b-primary-2' : 'text-white'}`}
            onClick={() => setTab(MINI_GAME_CART_BET)}
          >
            {/* 배팅카트 {cart?.pick_list?.length || Object.keys(cart?.mini_game)?.length} */}
            배팅카트 {cart?.pick_list?.length}
          </button>
          <button
            className={`w-1/2 h-10 font-normal hover:text-primary-2 hover:border-b hover:border-b-primary-2 ${tab === MINI_GAME_CART_HISTORY ? 'text-primary-2 border-b border-b-primary-2' : 'text-white'}`}
            onClick={() => {
              if (tab === MINI_GAME_CART_HISTORY) return

              refetchParsingCasinoBetData()
              setTab(MINI_GAME_CART_HISTORY)
            }}
          >
            배팅 기록
          </button>
        </div>
        {tab === MINI_GAME_CART_BET && (
          <>
            <div>
              <div className='rounded'>
                <div className='flex font-normal justify-between text-primary-2 border-b border-[#3C3C3C] p-2'>
                  <div className='right number bet-cart-cnt text-14'>
                    {/* {cart?.pick_list?.length || Object.keys(cart?.mini_game)?.length} */}
                    {cart?.pick_list?.length}
                    폴더
                  </div>
                  <div className='left'>
                    <button
                      className='text-[#707478] text-14 font-normal'
                      onClick={() => dispatch(removeAllCartMiniGame())}
                    >
                      전체지우기
                    </button>
                  </div>
                </div>
                <div className=''>{renderMiniGameCart()}</div>
                <div className='border border-[#3C3C3C] pr-1 pl-3 flex justify-between items-center gap-10 py-2'>
                  <p className='text-12 font-bold flex-shrink-0'>배팅</p>
                  <div className='flex items-center gap-2'>
                    <button onClick={() => setCrashBet(0)}>
                      <img
                        src='/assets/images/icons/icon-rotate-white.svg'
                        alt='Icon Rotate'
                        title='Icon Rotate'
                        width='16'
                        height='19'
                      />
                    </button>
                    <input
                      className='input-primary auto-fill-input w-full placeholder:text-gray-500 focus:outline-none text-white text-14 h-8 p-3 rounded border-2 border-primary bg-black  '
                      placeholder='금액을 입력해주세요.'
                      value={formatCurrency(crashBet, ',')}
                      onChange={handleChangeTotalBet}
                    />
                  </div>
                </div>
                <div className='flex justify-between items-center gap-1 text-12 my-3'>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(5000)}
                  >
                    오천
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(10000)}
                  >
                    일만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(50000)}
                  >
                    오만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(100000)}
                  >
                    십만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(1000000)}
                  >
                    백만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(user.mMoney)}
                  >
                    맥스
                  </button>
                </div>
                <div className='p-2'>
                  <div>
                    <ul className='flex flex-col gap-1'>
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>총 배당:</p>
                        <p>
                          {/* {location.pathname === path.sports ? formattedTotalBetRate : formattedTotalBetRateMnGame} 배 */}
                          {formattedTotalBetRate} 배
                        </p>
                      </li>
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>당첨 예상금액</p>
                        <p>{formatCurrency(totalMoney, ',')} 원</p>
                      </li>
                      {/* MIN */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최소 배팅금액</p>
                        <p>{formatCurrency(limit?.min_bet_amount || 0, ',')} 원</p>
                      </li>

                      {/* MAX */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대 배팅금액</p>
                        <p>{formatCurrency(limit?.max_bet_amount || 0, ',')} 원</p>
                      </li>

                      {/* MAX REWARD */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대 당첨금액</p>
                        <p>{formatCurrency(limit?.max_bet_payout || 0, ',')} 원</p>
                      </li>

                      {/* COUNT ... */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대배당</p>
                        <p>{formatCurrency(limit?.max_payout || 0, ',')} 배</p>
                      </li>
                    </ul>
                  </div>
                  <button
                    className={cn(
                      'rounded-md bg-[#14161b] block w-full text-primary-2 py-2 my-2 font-bold hover:bg-primary-2 hover:text-black',
                      {
                        '!bg-gray-5 pointer-events-none hover:!bg-gray-5 ': loadingParsingCasino
                      }
                    )}
                    disabled={!token || loadingParsingCasino}
                    onClick={cart.pick_list.length ? handleBetting : () => {}}
                  >
                    배팅하기
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === MINI_GAME_CART_HISTORY && location.pathname && user && (
          <div className='flex flex-col my-4 gap-3'>
            {parsingCasinoBetData?.ParsingCasinoBettingList?.data &&
            parsingCasinoBetData?.ParsingCasinoBettingList.data.length > 0 ? (
              <>
                {parsingCasinoBetData?.ParsingCasinoBettingList.data.map((historyBet, index) => {
                  const result =
                    historyBet.tpcStatus === 'bet' ? (
                      <span className='border-pink-300 px-[15px] py-[4px] rounded-[5px] ps-0'>배팅</span>
                    ) : historyBet.tpcStatus === 'win' ? (
                      <span className='text-[#4986e8] px-[15px] py-[4px] rounded-[5px] ps-0'>당첨</span>
                    ) : historyBet.tpcStatus === 'draw px-[15px] py-[4px] rounded-[5px] ps-0' ? (
                      <span className='text-[#F79F2F]'>적특</span>
                    ) : (
                      <span className='text-[#ff0000] px-[15px] py-[4px] rounded-[5px] ps-0'>낙첨</span>
                    )

                  const textResultBetting = historyBet?.tpcResultBetting ? JSON.parse(historyBet?.tpcResultBetting) : []
                  const textSelectedItem = historyBet?.tpcSelectedItem ? JSON.parse(historyBet?.tpcSelectedItem) : []

                  return (
                    <div key={index} className={`${index > 0 ? 'border-t py-3' : 'border-none'} border-blue-1`}>
                      <div className='flex flex-col gap-2'>
                        <div className='flex flex-col gap-1'>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅타입</span>
                            <span className='text-12 text-white'>{historyBet.tpcProviderGame}</span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅접수</span>
                            <span className={`text-12 text-white rounded-full`}>
                              {textRender[textSelectedItem?.text]}
                            </span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>게임결과</span>
                            <span className={`text-12 text-white rounded-full`}>
                              {textRender[textResultBetting?.text] || '-'}
                            </span>
                          </div>
                        </div>
                        <div className='w-full h-px bg-[#3c3c3c]'></div>
                        <div className='flex flex-col gap-1'>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅시간</span>
                            <span className='text-12 text-white'>{historyBet.tpcRegDate}</span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>예상당첨금액</span>
                            <span className='text-12 text-white'>
                              <span className='text-[#fff]'>(</span>배팅{' '}
                              <span className='text-text-cart-1'>
                                {formatCurrency(historyBet.tpcBetAmount, ',')} <span className='text-white'>X</span>
                              </span>{' '}
                              배당 <span className='text-text-cart-1'>{historyBet.tpcRate}</span>
                              <span className='text-text-cart-1'>)</span> ={' '}
                              <span className='text-text-cart-2'>
                                {formatCurrency(historyBet.tpcBetAmount * historyBet.tpcRate, ',')}
                              </span>
                              <span className='text-[#fff]'>원</span>
                            </span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>결과당첨금액</span>
                            <span className='text-12 text-text-cart-2'>
                              {formatCurrency(historyBet.tpcBetAmount * historyBet.tpcRate, ',')}{' '}
                              <span className='text-white'>원</span>
                            </span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅결과</span>
                            <span className='text-12 text-white'>{result}</span>
                          </div>
                        </div>
                        <span className='text-12 text-[#AAAAAA]'>
                          *자세한 내역 및 취소요청은 배팅내역 페이지에서 확인 가능합니다.
                        </span>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className='flex flex-col p-5 bg-black w-[95%] mx-auto text-12 rounded'>
                <p className='text-[#707478] mb-[20px]'>배팅내역이 존재하지 않습니다.</p>
                <p className='text-[#707478]'>*자세한 내역 및 취소요청은 배팅내역</p>
                <p className='text-[#707478]'>페이지에서 확인 가능합니다.</p>
              </div>
            )}
            {/* {miniGameBetData?.MiniGameBettingList?.data && miniGameBetData?.MiniGameBettingList.data.length > 0 ? (
              <>
                {miniGameBetData?.MiniGameBettingList.data.map((historyBet, index) => {
                  const result =
                    historyBet.tmStatus === 'bet' ? (
                      <span className='border-pink-300 px-[15px] py-[4px] rounded-[5px] ps-0'>배팅</span>
                    ) : historyBet.tmStatus === 'win' ? (
                      <span className='text-[#4986e8] px-[15px] py-[4px] rounded-[5px] ps-0'>당첨</span>
                    ) : historyBet.tmStatus === 'draw px-[15px] py-[4px] rounded-[5px] ps-0' ? (
                      <span className='text-[#F79F2F]'>적특</span>
                    ) : (
                      <span className='bg-[#ff0000] px-[15px] py-[4px] rounded-[5px] ps-0'>낙첨</span>
                    )

                  const textResultBetting = historyBet?.tmResultBetting ? JSON.parse(historyBet?.tmResultBetting) : []
                  const textSelectedItem = historyBet?.tmSelectedItem ? JSON.parse(historyBet?.tmSelectedItem) : []

                  return (
                    <div key={index} className='p-4 bg-[#000]'>
                      <div className='flex flex-col gap-2'>
                        <div className='flex flex-col gap-1'>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅타입</span>
                            <span className='text-12 text-white'>{historyBet.tmProviderGame}</span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅접수</span>
                            <span className='text-12 text-white'>{textSelectedItem?.text}</span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅결과</span>
                            <span className='text-12 text-white'>{textResultBetting?.text}</span>
                          </div>
                        </div>
                        <div className='w-full h-px bg-[#3c3c3c]'></div>
                        <div className='flex flex-col gap-1'>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅시간</span>
                            <span className='text-12 text-white'>{historyBet.tmRegDate}</span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>예상당첨금액</span>
                            <span className='text-12 text-white'>
                              <span className='text-text-cart-1'>(</span>배팅{' '}
                              <span className='text-text-cart-1'>{historyBet.tmBetAmount} X</span> 배당{' '}
                              <span className='text-text-cart-1'>{historyBet.tmRate}</span>
                              <span className='text-text-cart-1'>)</span> ={' '}
                              <span className='text-text-cart-2'>{historyBet.tmBetAmount * historyBet.tmRate}</span>
                              <span className='text-text-cart-1'>원</span>
                            </span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>결과당첨금액</span>
                            <span className='text-12 text-[#a94442]'>
                              {historyBet.tmBetAmount * historyBet.tmRate} <span className='text-white'>원</span>
                            </span>
                          </div>
                          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
                            <span className='text-12 text-[#AAAAAA]'>배팅결과</span>
                            <span className='text-12 text-white'>{result}</span>
                          </div>
                        </div>
                        <span className='text-12 text-[#AAAAAA] max-w-[70%]'>
                          *자세한 내역 및 취소요청은 배팅내역 페이지에서 확인 가능합니다.
                        </span>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className='flex flex-col p-5 bg-black w-[95%] mx-auto text-12 rounded'>
                <p className='text-[#707478] mb-[20px]'>배팅내역이 존재하지 않습니다.</p>
                <p className='text-[#707478]'>*자세한 내역 및 취소요청은 배팅내역</p>
                <p className='text-[#707478]'>페이지에서 확인 가능합니다.</p>
              </div>
            )} */}
            <button
              className='block mx-auto my-2 text-blue-1'
              onClick={() => setQuery({ ...query, limit: (query?.limit || 0) + 10 })}
            >
              더보기
            </button>
          </div>
        )}
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
import { Modal } from 'antd'
import cn from 'classnames'
import { memo, useRef, useState } from 'react'
import Loading from 'src/components/Loading'
import { PaginationCustom } from 'src/components/Pagination/Pagination'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { BoardBetSport } from './components/BoardBetSport'
import { EmptyDataSports } from './components/EmptyDataSports'
import { GroupLiveSportByTime } from './components/GroupLiveSportByTime'
import { TablePolicySports } from './components/TablePolicySports'
import { useEventLiveSports } from './hooks/useEventLiveSports'
import { useLiveSports } from './hooks/useLiveSports'
import './index.scss'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
// import { useLiveSports } from './hooks/useLiveSports'

const LiveSports = () => {
  const { isBetting } = useCartLiveSportsStore()
  const { isOpenModalCartChange, handleCloseModalCartChange } = useEventLiveSports()
  const {
    sportList,
    categoriesLiveSports,
    isOpenBoardBetSport,
    isLoadingLiveSports,
    isFirstTimeFetch,
    pagination,
    pageSize,
    sportsCodeActive,
    isLoadingRateInfo,
    isLoadingChangePagination,
    categoriesPure,
    handleChangePaginationSport,
    handleChangeCategory
  } = useLiveSports()
  const listSportsRef = useRef<HTMLDivElement>(null)
  const listSportsRef3XL = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const paginationRef3XL = useRef<HTMLDivElement>(null)
  const { isLgUp, isLaptop, is2XlUp, is3XlUp } = useViewport()
  const renderSportsList = () => {
    if (sportsCodeActive !== 0) {
      const categoryTarget = categoriesPure?.find((item) => item.sportsCode === sportsCodeActive)
      if (!categoryTarget) {
        return (
          <div className={`h-[200px]`}>
            <div className='h-full flex items-center justify-center'>
              <EmptyDataSports />
            </div>
          </div>
        )
      }
      if (categoryTarget?.maintenance) {
        return (
          <div className={`h-[200px]`}>
            <div className='h-full flex items-center justify-center'>
              <EmptyDataSports maintainMsg={categoryTarget?.maintenance_message} />
            </div>
          </div>
        )
      }
    }
    return (
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-1 px-1 py-1 bg-secondary-2'>
          <span className='text-[24px] text-primary'>※</span>
          <span className='text-10 text-[#707478]'>
            실시간에서 스페셜을 모두 아우르는 최고의 배팅마켓을 제공합니다.
            <br />
            실시간 배팅규정은
            <span className='text-primary cursor-pointer'>“실시간 규정확인”</span> 을 열람하시면 확인 가능합니다.
          </span>
        </div>
        {isLoadingChangePagination ? (
          <Loading />
        ) : isLoadingLiveSports ? (
          sportList.length === 0 ? (
            <Loading />
          ) : (
            <GroupLiveSportByTime isLoading={isLoadingLiveSports} listGroupSportByKeys={sportList} />
          )
        ) : sportList.length === 0 ? (
          <div className={`h-[200px]`}>
            <div className='h-full flex items-center justify-center'>
              <EmptyDataSports />
            </div>
          </div>
        ) : (
          <GroupLiveSportByTime listGroupSportByKeys={sportList} />
        )}
      </div>
    )
  }

  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft)
      setScrollLeft(sliderRef.current.scrollLeft)
    }
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (!isDragging || !sliderRef.current) return

    e.preventDefault()
    const x = e.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1.2 // tốc độ kéo
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  return (
    <div className='flex flex-col gap-2 mt-2 lg:px-9'>
      <Modal open={isOpenModalCartChange} footer={null} closeIcon={false} rootClassName='sports-modal'>
        <div className='flex flex-col bg-black-1 border-black-2 border'>
          <div className='flex flex-col gap-y-4 py-8 items-center'>
            <img src='/danger.png' alt='alert' className='size-16' />
            <span className='text-white'>선택된 경기중 변경된 사항이 있습니다.</span>
          </div>
          <button
            className='bg-black-2 py-2 text-primary duration-150 hover:bg-primary hover:text-white'
            onClick={() => {
              handleCloseModalCartChange()
            }}
          >
            닫기[Close]
          </button>
        </div>
      </Modal>
      <Modal open={isBetting} footer={null} closeIcon={false} centered={true} rootClassName='sports-modal'>
        <Loading />
      </Modal>
      {/* begin category mobile */}
      <div className='overflow-x-auto scrollbar-hide lg:hidden'>
        <div className='w-max'>
          {categoriesLiveSports.map((category) => {
            return (
              <button
                key={category.sportsCode}
                onClick={(e) => {
                  if (sportsCodeActive === category.sportsCode) return
                  handleChangeCategory({ sportsCode: category.sportsCode })
                  e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}
                className={cn('float-left inline w-20 h-16', {
                  'bg-primary': sportsCodeActive === category.sportsCode,
                  'bg-secondary-2': sportsCodeActive !== category.sportsCode
                })}
              >
                <div className='flex flex-col items-center justify-center gap-2 w-20 h-16 py-3 px-0 '>
                  <div className='flex items-center justify-center'>
                    <img src={category.imgPath} alt={category.sportsName} className='size-4' />
                  </div>
                  <span className='text-white text-12 md:text-gray-5 md:text-[9px]'>{category.sportsName}</span>
                </div>
              </button>
            )
          })}
        </div>
      </div>
      {/* end category mobile */}
      {/* begin category lg screen: 1024px up */}
      <div className='hidden relative lg:flex items-center justify-between gap-4 group pb-4 pt-2'>
        <button
          key={categoriesLiveSports[0].sportsCode}
          onClick={() => {
            if (sportsCodeActive === categoriesLiveSports[0].sportsCode) return
            handleChangeCategory({ sportsCode: categoriesLiveSports[0].sportsCode })
          }}
          className={cn(
            'flex items-center justify-between gap-3 min-w-[120px] 3xl:min-w-44 p-2.5 rounded-md group duration-150',
            {
              'bg-primary is-active': sportsCodeActive === categoriesLiveSports[0].sportsCode,
              'bg-secondary-2': sportsCodeActive !== categoriesLiveSports[0].sportsCode
            },
            {
              'bg-primary is-active': sportsCodeActive === 0 && categoriesLiveSports[0].sportsName === 'All'
            }
          )}
        >
          <div className='flex items-center gap-2'>
            <img src={categoriesLiveSports[0].imgPath} alt={categoriesLiveSports[0].sportsName} className='size-4' />
            <span className='text-white text-12 line-clamp-1'>{categoriesLiveSports[0].sportsName}</span>
          </div>
          <button className='px-1 rounded bg-blue-3 group-[.is-active]:bg-black text-12'>
            {categoriesLiveSports[0].count}
          </button>
        </button>
        <div className='w-full overflow-auto scrollbar-hide'>
          <div className='flex items-center gap-4 overflow-hidden'>
            <nav
              ref={sliderRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              className='flex items-center gap-2 space-x-4 overflow-x-auto cursor-grab hide-scrollbar'
            >
              {categoriesLiveSports.slice(1).map((category, index) => {
                return (
                  <button
                    key={category.sportsCode}
                    onClick={(e) => {
                      if (sportsCodeActive === category.sportsCode) return
                      handleChangeCategory({ sportsCode: category.sportsCode })
                      e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }}
                    className={cn(
                      'flex items-center justify-between gap-3 min-w-[120px] 3xl:min-w-44 p-2.5 rounded-md group duration-150',
                      {
                        'bg-primary is-active': sportsCodeActive === category.sportsCode,
                        'bg-secondary-2': sportsCodeActive !== category.sportsCode,
                        hidden: (isLgUp || isLaptop) && index > 999
                      },
                      {
                        'bg-primary is-active': sportsCodeActive === 0 && category.sportsName === 'All'
                      }
                    )}
                  >
                    <div className='flex items-center gap-2'>
                      <img src={category.imgPath} alt={category.sportsName} className='size-4' />
                      <span className='text-white text-12 line-clamp-1'>{category.sportsName}</span>
                    </div>
                    <button className='px-1 rounded bg-blue-3 group-[.is-active]:bg-black text-12'>
                      {category.count}
                    </button>
                  </button>
                )
              })}
            </nav>
          </div>
        </div>
      </div>
      {/* end category lg screen: 1024px up */}
      {/* begin sports main content 2xl screens */}
      <div
        ref={listSportsRef}
        style={{
          height: `calc(100dvh - ${listSportsRef.current?.getBoundingClientRect().top}px - ${paginationRef.current && paginationRef.current.clientHeight >= 40 ? paginationRef.current?.clientHeight + 12 : 40 + 12}px)`
        }}
        className={
          'overflow-y-auto main_scrollbar px-2 lg:px-0 pb-2 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 3xl:hidden gap-2'
        }
        id={!is3XlUp ? 'main-content-sports' : ''}
      >
        <div className='overflow-y-auto main_scrollbar'>{renderSportsList()}</div>
        <div className='hidden xl:block xl:overflow-y-auto main_scrollbar'>
          {isOpenBoardBetSport ? (
            <BoardBetSport isOpen={isOpenBoardBetSport} />
          ) : (
            <TablePolicySports isLoading={isLoadingRateInfo} />
          )}
          {/* <LivematchProWidget /> */}
        </div>
        {!isFirstTimeFetch && sportList.length > 0 && !is3XlUp && (
          <div className={cn('pb-3 flex items-center justify-center block lg:hidden')} ref={paginationRef} style={{ transform: 'translate(0px, 20px)' }}>
            <PaginationCustom
              current={pagination.current_page}
              onChange={handleChangePaginationSport}
              defaultCurrent={pagination.current_page}
              total={pagination.total}
              defaultPageSize={pageSize}
            />
          </div>
        )}
      </div>

      {/* end sports main content 2xl screens */}
      {/* begin sports main content 3xl screens */}
      <div
        ref={listSportsRef3XL}
        style={{
          height: `calc(100dvh - ${listSportsRef3XL.current?.getBoundingClientRect().top}px)`
        }}
        className={'px-2 lg:px-0 pb-2 hidden 3xl:grid 3xl:grid-cols-2 3xl:gap-4 gap-2'}
      >
        <div className='overflow-y-auto main_scrollbar' id={is3XlUp ? 'main-content-sports' : ''}>
          {renderSportsList()}
        </div>
        <div className='hidden 3xl:block 3xl:overflow-y-auto main_scrollbar'>
          {isOpenBoardBetSport ? (
            <BoardBetSport isOpen={isOpenBoardBetSport} />
          ) : (
            // <LivematchProWidget />
            <TablePolicySports isLoading={isLoadingRateInfo} />
          )}
        </div>
        {!isFirstTimeFetch && sportList.length > 0 && (
          <div className={cn('pb-3 flex items-center justify-center')} ref={paginationRef3XL}>
            <PaginationCustom
              onChange={handleChangePaginationSport}
              defaultCurrent={pagination.current_page}
              total={pagination.total}
              defaultPageSize={pageSize}
            />
          </div>
        )}
      </div>
      {/* end sports main content 3xl screens */}
      {!isFirstTimeFetch && sportList.length > 0 && !is3XlUp && (
        <div className={cn('pb-3 flex items-center justify-center hidden lg:block m-auto')} ref={paginationRef}>
          <PaginationCustom
            onChange={handleChangePaginationSport}
            defaultCurrent={pagination.current_page}
            total={pagination.total}
            defaultPageSize={pageSize}
          />
        </div>
      )}
      
    </div>
    
  )
}

export default memo(LiveSports)

import InfiniteScroll from 'react-infinite-scroll-component'
import Collapse from 'src/components/Collapse'
import { FolderBonusQueryRes, GroupSportListByKey } from 'src/types/sports.type'
import { FoldersBonus } from './FoldersBonus'
import { GroupSportList } from './GroupSportList'
import { useQuery } from '@apollo/client'
import { GET_LOGO_PRIORITY_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import cn from 'classnames'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
type Props = {
  listGroupSportByKeys: GroupSportListByKey[]
  folderBonusQuery?: FolderBonusQueryRes
  isLoadingFolderBonus: boolean
}

export const GroupSportByTime = ({ listGroupSportByKeys, folderBonusQuery, isLoadingFolderBonus }: Props) => {
  const { filteredSports, handleChangeFilteredSports } = useSportSearchStore()
  const { data: configMeta } = useQuery(GET_LOGO_PRIORITY_QUERY, { context: { apiName: 'auth' } })
  const siLogo2 = configMeta?.PageSiteQuery?.siLogo2
  return (
    <div className='3xl:w-full flex flex-col gap-4'>
      <FoldersBonus isLoadingFolderBonus={isLoadingFolderBonus} folderBonusQuery={folderBonusQuery} />
      <div className='flex items-center gap-1 px-3 py-1 bg-secondary-2'>
        <span className='text-[24px] text-primary'>※</span>
        <span className='text-10 text-[#707478]'>
          실시간에서 스페셜을 모두 아우르는 최고의 배팅마켓을 제공합니다.
          <br />
          실시간 배팅규정은
          <span className='text-primary cursor-pointer'>“실시간 규정확인”</span> 을 열람하시면 확인 가능합니다.
        </span>
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          <button
            className={cn('text-14 font-medium px-4 py-1 rounded duration-150', {
              'text-white bg-primary pointer-events-none': filteredSports.isFavorite,
              'text-black bg-white hover:bg-primary hover:text-white': !filteredSports.isFavorite
            })}
            onClick={() => {
              if (filteredSports.isFavorite) return
              handleChangeFilteredSports({ ...filteredSports, isFavorite: true })
            }}
          >
            인기순
          </button>
          <button
            className={cn('text-14 font-medium px-4 py-1 rounded duration-150', {
              'text-black bg-white hover:bg-primary hover:text-white': filteredSports.isFavorite,
              'text-white bg-primary pointer-events-none': !filteredSports.isFavorite
            })}
            onClick={() => {
              if (!filteredSports.isFavorite) return
              handleChangeFilteredSports({ ...filteredSports, isFavorite: false })
            }}
          >
            시간순
          </button>
        </div>
        <div className='flex items-center gap-2'>
          <button
            className={cn('text-14 font-medium px-4 py-1 rounded duration-150', {
              'text-primary': filteredSports.isSortBy24h,
              'text-white hover:text-primary': !filteredSports.isSortBy24h
            })}
            onClick={() => {
              handleChangeFilteredSports({
                isFavorite: filteredSports.isFavorite,
                isSortBy24h: !filteredSports.isSortBy24h
              })
            }}
          >
            24시간
          </button>
          <button
            className={cn('text-14 font-medium px-4 py-1 rounded duration-150', {
              'text-primary': filteredSports.isSortByTomorrow,
              'text-white hover:text-primary': !filteredSports.isSortByTomorrow
            })}
            onClick={() => {
              handleChangeFilteredSports({
                isFavorite: filteredSports.isFavorite,
                isSortByTomorrow: !filteredSports.isSortByTomorrow
              })
            }}
          >
            내일
          </button>
          <button
            className={cn('text-14 font-medium px-4 py-1 rounded duration-150', {
              'text-primary': filteredSports.isSortByLeague,
              'text-white hover:text-primary': !filteredSports.isSortByLeague
            })}
            onClick={() => {
              handleChangeFilteredSports({
                isFavorite: filteredSports.isFavorite,
                isSortByLeague: !filteredSports.isSortByLeague
              })
            }}
          >
            리그
          </button>
        </div>
      </div>
      <InfiniteScroll
        className='overflow-hidden space-y-2 3xl:w-full'
        dataLength={listGroupSportByKeys.length || 0}
        next={() => {}}
        hasMore={false}
        scrollableTarget={'main-content-sports'}
        loader={
          <div className='flex justify-center items-center w-full py-4 animate-pulse'>
            <img src={siLogo2} className='animate-pulse w-1/2 max-w-[200px]' alt='logo loading' />
          </div>
        }
      >
        {listGroupSportByKeys.length
          ? listGroupSportByKeys.map((listGroupSportByKeyItem) => {
              if (!listGroupSportByKeyItem.listGroupSport.length) {
                console.log('?', listGroupSportByKeyItem)
                return
              }
              return (
                <Collapse
                  key={listGroupSportByKeyItem.key}
                  classRoot='bg-black-4 rounded-md'
                  activeDefault={true}
                  head={
                    <div className='flex justify-between items-center w-full gap-2'>
                      <div className='flex items-center gap-1.5 2xl:gap-2 text-12'>
                        <img
                          src={listGroupSportByKeyItem.listGroupSport[0].logo_sports}
                          alt='icon'
                          title='icon'
                          className='size-[20px]'
                        />
                        <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
                        <img
                          src={listGroupSportByKeyItem.listGroupSport[0].logo_location}
                          alt='icon'
                          title='icon'
                          width='18'
                          height='12'
                        />
                        <p>{listGroupSportByKeyItem.listGroupSport[0].location_name}</p>
                        <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
                        <p>{listGroupSportByKeyItem.listGroupSport[0].league_name || ''}</p>
                      </div>
                    </div>
                  }
                >
                  <GroupSportList key={listGroupSportByKeyItem.key} listGroupSportByKey={listGroupSportByKeyItem} />
                </Collapse>
              )
            })
          : null}
      </InfiniteScroll>
    </div>
  )
}

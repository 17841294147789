/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useQuery } from '@apollo/client'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { FaChevronCircleDown } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NotFoundState } from 'src/components/NotFoundState'
import path from 'src/constants/path'
import { GET_MINI_GAME_EVEN_ODD } from 'src/libs/apis/graphql/queries/parsing-casino.query'
import { useGetPageSiteQuery } from 'src/libs/hooks/api/useGetPageSiteQuery'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { useDocumentVisibility } from 'src/libs/hooks/useDocumentVisibility'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { setShowCart } from 'src/libs/stores/common.reducer'
import { addItemToCartMiniGame, BetMiniGameItem, removeAllCartMiniGame } from 'src/libs/stores/minigame.reducer'
import { usePusher } from 'src/providers/PusherProvider'

export interface GET_MINI_GAME {
  EvenOddQuery: EvenOddQuery
}

export interface EvenOddQuery {
  data: OddEvenBetItem[]
  round_id: string
  now: number
  blocking_time?: number
}

export interface OddEvenBetItem {
  key: string
  text: string
  rate: number
  color: string
}

export interface Option {
  text: string
  rate: number
  transId: string
  color: string
}

type Props = {
  tabActive?: number
  handleLockedPlay?: () => void
  handleUnlockedPlay?: () => void
  refreshHistory?: () => Promise<void>
}

function formatTimeFromSeconds(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export default function EvenOddBet({
  tabActive,
  handleLockedPlay = () => {},
  handleUnlockedPlay = () => {},
  refreshHistory = async () => {}
}: Props) {
  const pusher = usePusher()
  const dispatch = useDispatch()
  const [countdownTimer, setCountdownTimer] = useState<number>(59)
  const [isRefetchApi, setIsRefetchApi] = useState(false)
  const [dataMiniGame, setDataMiniGame] = useState<GET_MINI_GAME | null>(null)
  const location = useLocation()

  const configMeta = useGetPageSiteQuery()
  const siLogo2 = configMeta?.data?.PageSiteQuery?.siLogo2
  const [minigameName, setMiniGameName] = useState('')
  const [minigameNameTitle, setMiniGameNameTitle] = useState('')

  const nameMiniGame = () => {
    switch (location.pathname) {
      case path.mgm_odd_even:
        return 'mgm_oe'
      case path.lotus_odd_even:
        return 'lotus_oe'
      default:
        return ''
    }
  }

  const miniGameName = () => {
    switch (location.pathname) {
      case path.mgm_odd_even:
        return 'MGM'
      case path.lotus_odd_even:
        return 'LOTUS'
      default:
        return 'MGM'
    }
  }

  const nameMiniGameTitle = () => {
    switch (location.pathname) {
      case path.mgm_odd_even:
      case path.lotus_odd_even:
        return '홀짝'
      default:
        return '홀짝'
    }
  }

  const {
    data: dataMini,
    refetch: refetchGame,
    loading: loading1,
    error
  } = useQuery<GET_MINI_GAME>(GET_MINI_GAME_EVEN_ODD, {
    variables: { game: minigameName },
    context: {
      apiName: 'parsing-casino'
    },
    onCompleted: (data) => {
      setDataMiniGame(data)
      const now = data.EvenOddQuery.now
      setCountdownTimer(now)
      setIsRefetchApi(true)
      if (!data.EvenOddQuery.round_id) {
        toast.error('Game is not available')
      }
      dispatch(removeAllCartMiniGame())
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: location.pathname !== path.lotus_odd_even && location.pathname !== path.mgm_odd_even && minigameName !== ''
  })

  useEffect(() => {
    setMiniGameName(nameMiniGame())
    setMiniGameNameTitle(nameMiniGameTitle())
    refetchGame()
  }, [location.pathname, refetchGame])

  useDocumentVisibility({
    cbActiveTab: refetchGame
  })

  const loading = loading1
  const data = dataMini?.EvenOddQuery

  const round_id = data?.round_id?.split('_').at(-1)
  const blocking_time = data?.blocking_time || 30
  const { cart } = useCartMiniGameStore()
  const { width } = useViewport()

  const addToCart = ({ betMiniGameItem }: { betMiniGameItem: BetMiniGameItem }) => {
    if (width < 1280) {
      dispatch(setShowCart(true))
    }
    dispatch(addItemToCartMiniGame(betMiniGameItem))
  }

  useEffect(() => {
    if (location.pathname === path.mini_boscore_ladder1 || location.pathname === path.mini_boscore_ladder2) {
      return
    }
    if (countdownTimer !== null && countdownTimer <= 0) {
      handleLockedPlay()
      dispatch(removeAllCartMiniGame())
      setCountdownTimer(0)
      refetchGame()
      handleRefetchHistory()
      setIsRefetchApi(false)
      return
    }
    if (!isRefetchApi) {
      return
    }
    if (dataMiniGame) {
      if (countdownTimer && countdownTimer <= blocking_time) {
        handleLockedPlay()
        dispatch(removeAllCartMiniGame())
      } else {
        handleUnlockedPlay()
      }
    }
  }, [countdownTimer, dataMiniGame])

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdownTimer((prev) => (prev !== 0 ? prev - 1 : prev))
    }, 1000)
    return () => clearInterval(interval)
  }, [location.pathname])

  useEffect(() => {
    const channel = pusher?.subscribe('parsing-casino-result-channel')
    channel?.bind('parsing-casino-result-event', async (data: { game: string }) => {
      const currentGameType = 'mgm_odd_even'
      if (currentGameType === data.game) {
        await refetchGame()
        await refreshHistory()
      }
    })

    return () => {
      channel?.unbind('parsing-casino-result-event')
      pusher?.unsubscribe('parsing-casino-result-channel')
    }
  }, [location.pathname, refetchGame, pusher])

  const handleRefetchHistory = async () => {
    setTimeout(() => {
      refreshHistory()
    }, 15000)
  }

  const categoryRender: { [key: string]: string } = {
    홀: '(홀/짝 맞추기)',
    짝: '(홀/짝 맞추기)'
  }

  return (
    <div className='w-full h-full justify-center items-center flex flex-col'>
      {loading && !data ? (
        <div className={`h-[200px]`}>
          <div className='h-full flex items-center justify-center '>
            <img src={siLogo2} className='animate-pulse w-1/2 max-w-[200px]' alt='logo loading' />
          </div>
        </div>
      ) : !data ? (
        <NotFoundState message={error?.message} />
      ) : (
        <div className='bg-[#101216] h-full w-full p-2 gap-3 rounded-md'>
          <div className='bg-[#030303] rounded-md p-4 h-[85px] flex justify-between'>
            <div className='flex gap-3 items-start'>
              <div className='flex items-start pt-1'>
                <img src='/assets/icons/star_1.svg' />
              </div>
              <div className='flex flex-col'>
                <p className='text-[25px] flex'>
                  <span className='hidden lg:block'>
                    {miniGameName()}({minigameNameTitle})<span className='mx-2'>-</span>
                  </span>
                  <span>
                    <span className='text-primary'> {round_id}</span>회차
                  </span>
                </p>
                <p className='text-12 hidden lg:block'>1분단위로 추첨이 이루어지며 하루 1440회차 진행</p>
              </div>
            </div>
            <div className=''>
              <Countdown seconds={countdownTimer} />
            </div>
          </div>
          <div className='rounded-md p-4 bg-[#030303]'>
            <div className=''>
              <div className='flex p-1 items-center mb-2'>
                <FaChevronCircleDown color='#a0a0a0' />
                <p className='text-9 text-secondary-6 pl-2'>카드 두장의 숫자합 홀/짝</p>
              </div>
              <div className='grid grid-cols-2 gap-1 items-stretch h-full'>
                {data &&
                  data?.data.map((item) => (
                    <button
                      className={cn(
                        'col-span-1 row-span-1 hover:bg-primary text-white p-4 rounded flex items-center justify-center gap-3 shadow-custom-shadow',
                        {
                          'bg-secondary hover:bg-primary':
                            !cart.pick_list.length ||
                            (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== item?.key),
                          'bg-primary is-active':
                            cart.pick_list.length && cart.pick_list[0].selectedKeyItem === item?.key
                        }
                      )}
                      onClick={() => {
                        addToCart({
                          betMiniGameItem: {
                            miniGameType: 'parsing-casino',
                            selectedKeyItem: item?.key || '',
                            miniGameItem: {
                              category: categoryRender[item?.text] || '',
                              rate: item?.rate || 0,
                              text: item?.text || '',
                              transId: data?.round_id || ''
                            }
                          }
                        })
                      }}
                      key={item?.key}
                    >
                      <div className='flex flex-col items-center'>
                        <p className='w-[70px] h-[70px] flex flex-col items-center justify-center rounded-full bg-gradient-to-b from-[#1d1d1d] to-[#2c2c2c] font-bold border-black-1 border'>
                          <span
                            className={cn(' text-20', {
                              'text-blue-2': item?.color === 'blue',
                              'text-red-1': item?.color === 'red'
                            })}
                          >
                            {item.text}
                          </span>
                          <span className='text-16'>{item.rate?.toFixed(2)}</span>
                        </p>
                      </div>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Countdown = ({ seconds }: { seconds: number }) => {
  return (
    <div className='flex gap-1 h-full'>
      <div className='hidden lg:flex bg-[#333333] text-30 font-bold p-3 rounded-md items-center'>0</div>
      <div className='hidden lg:flex bg-[#333333] text-30 font-bold p-3 rounded-md items-center'>0</div>
      <div className='hidden lg:flex items-center text-32'>:</div>
      <div className='bg-[#333333] text-30 font-bold p-3 rounded-md flex items-center'>{Math.floor(seconds / 10)}</div>
      <div className='bg-[#333333] text-30 font-bold p-3 rounded-md flex items-center'>{seconds % 10}</div>
    </div>
  )
}

import { TableColumnsType } from 'antd'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { DataMoneyType } from './ReCharge'
import Button from 'src/components/ui/Button'

export const getRechargeColumns: () => TableColumnsType<DataMoneyType> = () => {
  return [
    { title: '신청시간', dataIndex: 'miRegDate', key: 'miRegDate', width: '150px', align: 'center' },
    { title: '타입', dataIndex: 'miWallet', key: 'miWallet', width: '100px', align: 'center' },
    {
      title: '입금금액',
      dataIndex: 'miBankMoney',
      key: 'miBankMoney',
      width: '80px',
      align: 'center',
      render: (value) => formatCurrency(value) + ' 원'
    },
    {
      title: '보너스금액',
      dataIndex: 'miBonus',
      key: 'miBonus',
      width: '150px',
      align: 'center'
    },
    {
      title: '합계',
      dataIndex: 'miTotalMoney',
      key: 'miTotalMoney',
      width: '100px',
      align: 'center',
      render: (value) => formatCurrency(value) + ' 원'
    },
    { title: '처리시간', dataIndex: 'mProcessDate', key: 'mProcessDate', width: '150px', align: 'center' },
    {
      title: '상태',
      dataIndex: 'miStatus',
      key: 'miStatus',
      width: '100px',
      align: 'center',
      render: (value) => (
        <Button
          className={` w-[70px] h-8 flex items-center justify-center rounded text-14 border mx-auto ${value === '대기' ? 'text-red-500 border-red-500' : 'text-primary border-primary'}`}
        >
          {value}
        </Button>
      )
    }
  ]
}
